import React from 'react';
import { Button, Grid, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import { LopaConfig, SetSeatsConfig, TechSpecsConfig } from '.';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { FormSkeletonLoader } from '../../Elements';
const Header = ({ skeletonLoader, seatConfig, setSeatConfig, setSeatDetails, seatarr, selectAllDisabled, disabledSeatsSelection, windowArr, seatWindFlag, selectedAllWindowSeats, setSeatWindFlag, setSeatarr, setWindowArr, selectAllWindowOrSeat, getSeatFindingQuestions, getSeatFindingsAns, getSeatingConfig, getCurrentFormDetail,disabledSeat,getResponseBack }) => {
  return (
    <div className="edit-config">
      {skeletonLoader?<div style={{ minHeight: '380px', width:'380px'}}>  <FormSkeletonLoader /> </div>:
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className='flex-centered'>
        {seatConfig?.interior_config_details?.length ? (
            <FormControl component="fieldset" style={{borderRight:'groove'}}>
              <FormLabel component="legend"></FormLabel>
              <RadioGroup
                row
                aria-label="seat-window"
                name="seat-window"
                value={seatWindFlag}
                onChange={(e) => { setSeatWindFlag(Number(e.target.value)); setSeatarr([]); setWindowArr([]) }}
              >
                <FormControlLabel
                  style={{marginLeft:'0px',marginRight:'2px'}}
                  value={1}
                  control={<Radio size='small' style={{color:'white'}} />}
                  label={<a style={{padding:'2px'}}>Seats</a>}
                />
                <FormControlLabel
                  style={{marginLeft:'0px',marginRight:'12px'}}
                  value={0}
                  control={<Radio size='small' style={{color:'white'}} />}
                  label={<a style={{padding:'2px'}}>Windows</a>}
                />
              </RadioGroup>
            </FormControl>
          ) : null}
        {seatConfig?.interior_config_details?.length ? 
          <ul className="list-inline"  style={{ marginLeft:'12px' }}>
          <li className="list-inline-item" onClick={() => selectAllWindowOrSeat()}>
            <Button variant='outlined' style={{color:'white',border:'white groove',fontSize:'12px'}} color='white' size='small'>{selectedAllWindowSeats ? 'Unselect All' : 'Select All'}</Button>
          </li>
          <li className="list-inline-item">
            <a>
              {seatWindFlag === 1 ? seatarr.length : windowArr.length} {seatWindFlag === 1 ? 'Seat(s) ' : 'Window(s) '}selected
            </a>
          </li>
          </ul>:null}
          {/* <li className="list-inline-item" >
          {seatWindFlag === 1 && disabledSeat ?
              <a onClick={disabledSeatsSelection} size='small'style={{ width:'181px',color:disabledSeat ?'#ffffff' : 'rgba(255,255,255,0.4)'}}>
                {selectAllDisabled ? 'Deselect Disabled Seats' : 'Select Disabled Seats'}
              </a>:null}
          </li> */}
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item xs={12} md={6} className='flex-centered' style={{justifyContent:'right'}} >
          <ul className="list-inline" >
          {seatConfig?.interior_config_details?.length ?<>
            <li className="list-inline-item ">
              <Button  
                style={{ color: seatarr?.length || windowArr?.length ? '#ffffff' : 'rgba(255,255,255,0.4)',cursor:!(seatarr?.length || windowArr?.length )?'not-allowed':'', fontSize: '12px', border:seatarr?.length || windowArr?.length ? 'groove #ffffff' : 'groove rgba(255,255,255,0.4)'}} 
                onClick={() =>{ if(seatarr?.length || windowArr?.length ) { setSeatDetails({ modal:true }); getSeatFindingQuestions(seatWindFlag); getSeatFindingsAns(seatWindFlag, seatWindFlag === 1 ? seatarr : windowArr) }}} 
                variant='outlined' 
                size='small'>
                  Details
              </Button>
            </li>
            <li className="list-inline-item">
              <TechSpecsConfig
                disablebtn={seatarr?.length || windowArr?.length }
                getCurrentFormDetail={getCurrentFormDetail}
                getResponseBack={getResponseBack}
              />
            </li></>:null}
            <li className="list-inline-item">
              {Object.keys(seatConfig)?.length ?
                <LopaConfig
                  disablebtn={seatarr?.length || windowArr?.length }
                  is_lopa_details={seatConfig?.is_lopa_details}
                  getResponseBack={getResponseBack}
                /> : null
              }
            </li>
            <li className="list-inline-item">
              <SetSeatsConfig
                seatConfig={seatConfig.interior_config_details}
                setSeatConfig={setSeatConfig}
                getCurrentFormDetail={getCurrentFormDetail}
                getResponseBack={getSeatingConfig}
                disablebtn={seatarr?.length || windowArr?.length }
              />
            </li>
          </ul>
        </Grid>
      </Grid>}
    </div>
  )
}
export default Header;