import React, { useState, Fragment } from 'react';
import { withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress } from '@material-ui/core';
import { checkApiStatus } from '../../../utils_v2';
import { globalPutService } from '../../../utils_v2/globalApiServices';
import { imgStoragePath } from '../../../constants';
import { trackActivity } from '../../../utils/mixpanel';
import { castleAirInstance } from '../../../shared_elements/components';
const EditContentItem = ({params, editItem, toggleModalFn, documentCheckList, questionList, getResponseBack,currentFormDetail}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({})
    const [formData, setFormData] = useState(editItem.data);
    const onFieldChange = (keyParam, value) => {
        setFormData({...formData,[keyParam]:value})
    }
    const onResetErrorKey = (key) => {
        setError({...error,[key]:''});
    }
    const onSubmitEdit = () => {
        // add Validations
        let validationInputs = {
            name:formData.name && formData.name.trim().length ? '':'Please enter Name'
        }
        if(editItem.itemType === 'question'){
            validationInputs = {
                ...validationInputs,
                remarks:formData.value && (formData.value === 2 || formData.value === 3) ? (formData.remarks && formData.remarks.trim().length ? '':'Please enter Remarks'):''
            }
        }
        if(Object.keys(validationInputs).every((k) => { return validationInputs[k] === ''})){
            setLoading(true);
            let arr = [];
            if(editItem.itemType === 'section'){
                arr = documentCheckList.filter((section) => section.id !== formData.id).map((item) => item.name.toLowerCase());
            }else if(editItem.itemType === 'sub-section'){
                let subSecArr = documentCheckList.find(section => section.id === editItem.sectionId).sub_sections
                arr = subSecArr && subSecArr.length ? subSecArr.filter(subSec => subSec.id !== formData.id).map((item) => item.name.toLowerCase()):[];
            }else{
                let qusArr = questionList;
                arr = qusArr && qusArr.length ? qusArr.filter((item) => item.id !== formData.id).map((item) => item.name.toLowerCase()):[];
            }
            if(!arr.includes(formData.name.toLowerCase().trim())){
                globalPutService(`technical/v2/workorder/${params.workOrderSlug}/${editItem.itemType ==='section' ?'document-sections':editItem.itemType ==='sub-section'?'document-sub-sections':'document-checklists'}/${editItem.data.id}/?asset_type=${params.type}`, formData)
                .then(response => {
                    setLoading(false);
                    if(checkApiStatus(response)){
                        toggleModalFn();
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        trackActivity('Project Management', {
                            event_type: 'Section/Sub-Section Data Updated',
                            page_title: 'T003 Form',
                            workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                            edit_type: editItem.itemType ==='section' ?'document-sections':editItem.itemType ==='sub-section'?'document-sub-sections':'document-checklists',
                            id: editItem.data.id ? editItem.data.id : '',
                            request_body: formData ? formData : '',
                            response: response.data.data
                        });
                    }else{
                        enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                        trackActivity('Project Management', {
                            event_type: 'Section/Sub-Section Data Update Failed',
                            page_title: 'T003 Form',
                            workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                            edit_type: editItem.itemType ==='section' ?'document-sections':editItem.itemType ==='sub-section'?'document-sub-sections':'document-checklists',
                            id: editItem.data.id ? editItem.data.id : '',
                            request_body: formData ? formData : '',
                            error_msg: response.data.data,
                            error_source: 'Backend'
                        });
                    }
                })
            }else{
                setLoading(false);
                enqueueSnackbar('Name already exists', {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Section/Sub-Section Data Update Failed',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    edit_type: editItem.itemType ==='section' ?'document-sections':editItem.itemType ==='sub-section'?'document-sub-sections':'document-checklists',
                    id: editItem.data.id ? editItem.data.id : '',
                    request_body: formData ? formData : '',
                    error_msg:'Name Already Exists',
                });
            }
        }else{
            setError(validationInputs)
            trackActivity('Project Management', {
                event_type: 'Section/Sub-Section Data Update Failed',
                page_title: 'T003 Form',
                workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                edit_type: editItem.itemType ==='section' ?'document-sections':editItem.itemType ==='sub-section'?'document-sub-sections':'document-checklists',
                id: editItem.data.id ? editItem.data.id : '',
                error_detail:validationInputs ? validationInputs : '',
                error_source:'Frontend'
            });
        }
    }
    return(
        <Fragment>
            <Dialog
                    open={editItem.modal}
                    onClose={toggleModalFn}
                    className='project-management-modal'
                >
                <DialogTitle id="scroll-dialog-title">
                    Edit {editItem.itemType === 'section'? ' Section': editItem.itemType ==='sub-section' ? ' Sub-Section':' Question'}
                </DialogTitle>
                <DialogContent dividers={true}>
                    <div style={{width:'420px'}}>
                        <Grid container spacing={1}>
                            <Grid item md={12}>
                                <TextField
                                    required
                                    id={`name`}
                                    label={`${editItem.itemType === 'section' ? 'Section': editItem.itemType ==='sub-section' ? 'Sub-Section':'Checklist'} Name`}
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{shrink: true}}
                                    error={error.name?true:false}
                                    helperText={error.name ? error.name:''}
                                    value={formData.name?formData.name:''}
                                    onChange={(e) => onFieldChange('name', e.target.value)}
                                    onFocus={() => onResetErrorKey('name')}
                                    variant='outlined'
                                />
                            </Grid>

                            { editItem.itemType === 'question' ?
                                <Fragment>
                                    <Grid item md={12}>
                                        <TextField
                                            id={`notes`}
                                            label='Notes'
                                            fullWidth
                                            multiline
                                            maxRows={4}
                                            margin="normal"
                                            InputLabelProps={{shrink: true}}
                                            value={formData.notes?formData.notes:''}
                                            onChange={(e) => onFieldChange('notes', e.target.value)}
                                            variant='outlined'
                                        />
                                    </Grid>
                                    <Grid item md={12}  style={{display:currentFormDetail?.current_form?.status?.id==1 ?'none':''}}>
                                        <div>
                                            <label style={{fontSize:'13px'}}>Status</label>
                                            <ul className="list-inline spacing-list">
                                            {castleAirInstance ?
                                                <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => onFieldChange('value', formData.value === 4 ? null:4)}>
                                                    <img style={{height:'30px',borderRadius:'50%'}} src={`${imgStoragePath}${formData.value && formData.value === 4 ? 'redo_color.png':'redo_gray.png'}`} alt="" />
                                                </li>:null}
                                                <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => onFieldChange('value', formData.value === 1 ? null:1)}>
                                                    <img src={`${imgStoragePath}${formData.value && formData.value === 1 ? 'tick_color.png':'tick_gray.png'}`} alt="" />
                                                </li>
                                                <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => onFieldChange('value', formData.value === 2 ? null : 2)}>
                                                    <img src={`${imgStoragePath}${formData.value && formData.value === 2 ? 'cross_color.png':'cross_gray.png'}`} alt="" />
                                                </li>
                                                <li className="list-inline-item" style={{cursor:'pointer'}} onClick={(e) => onFieldChange('value',formData.value === 3 ? null : 3)}>
                                                    <img src={`${imgStoragePath}${formData.value && formData.value === 3 ? 'na_color.png':'na_gray.png'}`} alt="" />
                                                </li>
                                            </ul>
                                        </div>
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField
                                            required={formData.value && formData.value === 2 || formData.value === 3 ? true:false}
                                            id={`remarks`}
                                            label='Remarks'
                                            fullWidth
                                            margin="normal"
                                            multiline
                                            maxRows={4}
                                            InputLabelProps={{shrink: true}}
                                            error={error.remarks?true:false}
                                            helperText={error.remarks ? error.remarks:''}
                                            value={formData.remarks?formData.remarks:''}
                                            onChange={(e) => onFieldChange('remarks', e.target.value)}
                                            onFocus={() => onResetErrorKey('remarks')}
                                            variant='outlined'
                                        />
                                    </Grid>
                                </Fragment>
                                :null
                            }
                        </Grid>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button disabled={isLoading} onClick={toggleModalFn} color="primary" size='small' variant='outlined'>Cancel</Button>
                    <Button disabled={isLoading} onClick={onSubmitEdit} color="primary" size='small' variant='contained'>
                        {isLoading ? <CircularProgress size={24} /> : 'Save'}
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    )
}

export default withRouter(EditContentItem);