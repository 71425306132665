import { regexConstants, errorMsg1 } from '../../constants/regEx';
import { onCheckPermission, getLocalStorageInfo } from "../../utils_v2";
import { castleAirInstance } from '../../shared_elements/components';
export const projectHd = [
  { id: 'project', label: 'Project', sortOption: true },
  { id: 'project_remarks', label: 'Description', sortOption:castleAirInstance? true: false },
  { label: 'Location', sortOption: false },
  { id: 'lessee', label: 'Lessee', sortOption: true },
  { id: 'start_date', label: 'Start Date', sortOption: true },
  { id: 'end_date', label: 'Expected End Date', sortOption: true },
  { id:'status', label: 'Status', sortOption: true },
  { label: 'Action', sortOption: false },
]
export const projectFilterOps = {
  'name': {
    'inputType': 'text',
    'placeholder': 'Search by Project Name',
    'title': 'Project Name'
  },
  'project_number': {
    'inputType': 'text',
    'placeholder': 'Search by Project Number',
    'title': 'Project Number'
  },
  'msn': {
    'inputType': 'text',
    'placeholder': 'Search by Asset serial number',
    'title': 'Serial Number'
  },
  'work_order_number': {
    'inputType': 'text',
    'placeholder': 'Search by work order number',
    'title': 'WorkOrder Number'
  },
  'username': {
    'inputType': 'text',
    'placeholder': 'Search by Lead(s) / Form User(s)',
    'title': 'Lead(s) / Form User(s)'
  },
  'location': {
    'inputType': 'text',
    'placeholder': 'Search',
    'title': 'Location'
  },
  'forms': {
    'inputType': 'dropdown',
    'placeholder': 'Search by Forms',
    'title': 'Forms Type',
    'options':[],
    'labelKey':'name',
    'valueKey':'id',
    'multiple': true
  },
  'lessee': {
    'inputType': 'dropdown',
    'placeholder': 'Search by Lessee',
    'title': 'Lessee',
    'options':[],
    'labelKey':'name',
    'valueKey':'id',
    'multiple': true
  },
}
export const errorCode = {
  aircraft_msn:{
    0:'',
    4: errorMsg1
  },
  aircraft_msnObj:{
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  aircraft_registration:{
    0:'',
    4: errorMsg1
  },
  aircraft_registrationObj:{
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  engine_type_id:{
    0:'',
    1:'Please select Engine Type'
  },
  engine_type_idObj:{
    required: true
  },
  esn:{
    0:'',
    1:'Please enter Engine Type',
    4: errorMsg1
  },
  esnObj:{
    required: true,
  },
  operated_by: {
    0:'',
    4: errorMsg1
  },
  operated_byObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  location: {
    0:'',
    4: errorMsg1
  },
  locationObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  etops: {
    0:'',
    4: errorMsg1
  },
  etopsObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  tsn: {
    0:'',
    1:'Please enter TSN',
  },
  tsnObj: {
    required: true
  },
  csn: {
    0:'',
    1:'Please enter CSN',
  },
  csnObj: {
    required: true
  },
  engine_manufacturing_date: {
    0:'',
    1:'Please enter Engine Manufacturing Date',
  },
  engine_manufacturing_dateObj: {
    required: true
  },
  constructed_model: {
    0:'',
    4: errorMsg1
  },
  constructed_modelObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  inspection_performed_by: {
    0:'',
    4: errorMsg1
  },
  inspection_performed_byObj: {
    regexPattern: regexConstants.alphanumericWithHyphenSpace
  },
  name: {
    0:'',
    1:'Please select Project Name',
  },
  nameObj: {
    required: true
  },
  start_date: {
    0:'',
    1:'Please enter Start Date',
  },
  start_dateObj: {
    required: true
  },
  expected_end_date: {
    0:'',
    1:'Please enter Expected End Date',
  },
  expected_end_dateObj: {
    required: true
  },
  leads: {
    0:'',
    1:'Please select Project Lead',
  },
  leadsObj: {
    required: true
  },
  engineers: {
    0:'',
    1:'Please select Project Engineer',
  },
  engineersObj: {
    required: true
  },
  location: {
    0:'',
    1:'Please enter Location',
  },
  locationObj: {
    required: true
  },
  project_remarks: {
    0:'',
    1:'Please enter Project Description',
  },
  project_remarksObj: {
    required: true
  },
}
export const aircraftWorkOrderErrorCode = {
  apu_id: {
    0: '',
    1: 'Please select APU'
  },
  apu_idObj: {
    required:true
  },
  lg_id: {
    0: '',
    1: 'Please select Landing Gear'
  },
  lg_idObj: {
    required:true
  },
  propeller_id_id: {
    0: '',
    1: 'Please select Propeller'
  },
  propeller_id_idObj: {
    required:true
  },
  start_date:{
    0: '',
    1: 'Please select Start Date'
  },
  start_dateObj: {
    required: true
  },
  expected_end_date: {
    0:'',
    1: 'Please select Expected End Date'
  },
  expected_end_dateObj:{
    required:true
  },
  aircraft_type_id: {
    0:'',
    1:'Please Select Aircraft Type'
  },
  aircraft_type_idObj:{
    required: true
  },
  blueprint_id: {
    0:'',
    1:'Please Select Aircraft Blueprint'
  },
  blueprint_idObj:{
    required:true
  },
  date_of_manufacture: {
    0:'',
    1:'Please select Date of Manufacture'
  },
  date_of_manufactureObj:{
    required:true
  },
  location: {
    0:'',
    4:errorMsg1
  },
  locationObj: {
    regexPattern:regexConstants.alphanumericWithHyphenSpace
  },
  registration: {
    0:'',
    4:errorMsg1
  },
  registrationObj: {
    regexPattern:regexConstants.alphanumericWithHyphenSpace
  },
  line_number: {
    0:'',
    4:errorMsg1
  },
  line_numberObj: {
    regexPattern:regexConstants.alphanumericWithHyphenSpace
  },
  operator_storage_facility: {
    0:'',
    4:errorMsg1
  },
  operator_storage_facilityObj: {
    regexPattern:regexConstants.alphanumericWithHyphenSpace
  },
  aoc_regulation: {
    0:'',
    4:errorMsg1
  },
  aoc_regulationObj: {
    regexPattern:regexConstants.alphanumericWithHyphenSpace
  },
  inspection_types: {
    0:'',
    1:'Please select Inspection Type'
  },
  inspection_typesObj:{
    required:true
  },
  forms: {
    0: '',
    1: 'Please select Forms'
  },
  formsObj: {
    required:true
  }
}
export const projectContactHd = [
  { label: 'Name', sortOption: false },
  { label: 'Email', sortOption: false },
  { label: 'Designation', sortOption: false },
  { label: 'Phone', sortOption: false },
  { label: '', sortOption: false },
]

export const createProject = {
  name: '',
  start_date: null,
  expected_end_date: null,
  leads:[],
  files:[],
  engineers:[],
  operator_id:'',
  department:'',
  lessee:null,
  assets: [],
  leads: [],
  location:'',
  country:'',
  country_code:'',
  lat:'',
  lang:'',
  third_party: '',
  project_remarks:'',
  cor_required: true,
  project_form: 0,
  zoho_opportunity_id:'',
  client_contacts:[{name: '', email:'',designation:'', phone_number: ''}],
  operator_contacts:[{name: '', email:'',designation:'', phone_number: ''}],
  third_party_contacts: [{name: '', email:'',designation:'', phone_number: ''}],
  instructions: '',
  miscellaneous_comments:'',
  equipment_requirements: ''
}

export const alpWorkOrder = {
  create_msn:false,
  start_date: null,
  expected_end_date: null,
  lease_start_date:null,
  date_of_manufacture:null,
  location:'',
  country:'',
  status_as_of:null,
  forms:[],
  inspection_types:[],
  operator_storage_facility:'',
  line_number:'',
  aoc_regulation:'',
  records_inspection_on_start_date:null,
  records_inspection_on_end_date:null,
  physical_inspection_on_start_date:null,
  physical_inspection_on_end_date:null,
}

export const aircraftWorkOrder = {
  aircraft_id:'',
  start_date: null,
  expected_end_date: null,
  aircraft_type_id:null,
  blueprint_id: null,
  lease_start_date:null,
  date_of_manufacture:null,
  tsn:'',
  csn:'',
  location:'',
  registration:'',
  line_number:'',
  status_as_of:null,
  operator_storage_facility:'',
  aoc_regulation:'',
  no_of_gears:3,
  forms:[],
  inspection_types:[],
  no_of_galleys: {fwd:1 ,mid:0, aft:1},
  no_of_lavatories: {fwd:1,mid:0, aft:1},
  records_inspection_on_start_date:null,
  records_inspection_on_end_date:null,
  physical_inspection_on_start_date:null,
  physical_inspection_on_end_date:null,
  create_msn:false
}

export const assetTypeInfo = {
  1: {label:'Aircraft',key:'aircraft',params:'aircraft_id', errorMsg:'Please select Aircraft'},
  2: {label:'Engine',key:'engine',params:'engine_id', errorMsg:'Please select Engine'},
  3: {label:'APU',key:'apu',params:'apu_id', errorMsg:'Please select APU'},
  4: {label: 'Landing Gear',key:'lg',params:'lg_id', errorMsg:'Please select Landing Gear'},
  5: {label: 'Propeller',key: 'propeller',params:'propeller_id', errorMsg:'Please select Propeller'}
}
export const createEngine = {
  create_esn: false,
  forms:[],
  esn:'',
  slug:'',
  engine_type_id:null,
  aircraft_msn:'',
  aircraft_registration:'',
  aircraft_type_id:null,
  owned_by_type:'',
  location:'',
  country:'',
  tsn:'',
  csn:'',
  engine_status:null,
  install_position:null,
  engine_manufacturing_date:null,
  date_of_installation:null
}
export const projectsSelfPermission = (permissionKey, permissionOps, item, selfPermission) => {
  let permissionFlag = false;
  const userInfo = getLocalStorageInfo();
  if(onCheckPermission('project_management',permissionKey, permissionOps)){
    if(selfPermission === 2){
      permissionFlag = true
    }else{
      let ids = [];
      if(item?.created_by?.id){
          ids = [...ids, item?.created_by?.id]
      }
      if(item?.leads?.length){
        let leadIds = item.leads.map(user=>user.id);
        ids = [...ids, ...leadIds]
      }
      if(ids.includes(userInfo.user.id)){
        permissionFlag = true;
      }
    }
  }

  return permissionFlag
}
