import React, { useState } from 'react';
import { globalPostService } from '../../../utils_v2/globalApiServices';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemIcon, ListItemText, Checkbox, CircularProgress } from '@material-ui/core';
import { checkApiStatus } from '../../../utils_v2';
import { useSnackbar } from 'notistack';
import { DeletePopUp, PageLoader } from '../../../shared_elements';
const SeatDetails = ({params, existingFinding, seatarr, windowArr, seatWindFlag, seatDetails, seatFindingsQes, getCurrentFormDetail, updateFinding, seatFindingAns, getResponseBack, toggleModalFn,isLoading,setLoading}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [modal, setModal] = useState(false);

  const changeSeatStatus = (status) => {
    globalPostService(`technical/workorder/${params.workOrderSlug}/change-seat-status/`, {seats: seatarr, status:status})
      .then(response => {
        if(checkApiStatus(response)){
          toggleModalFn()
          getResponseBack('skeletonLoader')
          window.location.reload()
          enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }else{
          enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
        }
      })
  }

  const saveSeatFindingsAns = () => {
    const Payload = seatWindFlag === 1 ?
      { findings: [...(seatFindingsQes.findings || []), ...(seatFindingAns.findings || [])], seats: seatarr}
      :
      { findings: [...(seatFindingsQes.findings || []), ...(seatFindingAns.findings || [])], windows: windowArr};
      setLoading(true)
    globalPostService(`technical/workorder/${params.workOrderSlug}/${seatWindFlag === 1 ? 'add-seat-findings' : 'add-window-findings'}/`, Payload )
      .then(response => {
        if (checkApiStatus(response)) {
          toggleModalFn();
          getCurrentFormDetail()
          setTimeout(() => {
            getResponseBack();
            window.location.reload()
          }, 2000);
          setLoading(false)
          enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        } else {
          enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
      });
  }

  return(
    <>
    <Dialog
      open={seatDetails.modal}
      onClose={toggleModalFn}
      aria-labelledby="scroll-dialog-title"
    >
      <DialogTitle id="scroll-dialog-title">
        {seatWindFlag === 1 ? seatarr?.length : windowArr?.length} {seatWindFlag === 1?' Seat ': ' Window ' }Details
      </DialogTitle>
      <DialogContent dividers={true} style={{height:seatWindFlag === 1?'450px':'200px',width:'450px'}}>
        <p style={{color:'red',fontSize:'10px'}}>{seatFindingAns.status === 0?'This seat is disable.Please enaable to update the details':''}</p>
        <List>
          {seatFindingsQes.data?.map((item, index) => {
            const isSelected = seatFindingAns.findings?.some(finding => finding.id === item.id);
            return(
              <ListItem key={index} role={undefined} dense button>
                <ListItemIcon>
                  <Checkbox
                    disabled={seatFindingAns.status === 0 ?true:false}
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    checked={isSelected}
                    onChange={(e) => updateFinding(item, e.target.checked)}
                  />
                </ListItemIcon>
                <ListItemText id={item.id} primary={item.name} />
              </ListItem>
            )
          })}
        </List>
      </DialogContent>
      <DialogActions>
        { seatWindFlag === 1 ?
          <Button disabled={ seatFindingAns.findings.length ?true:false} style={{marginRight:'auto'}} onClick={() => setModal(true)} size="small" variant="contained" color={seatFindingAns.status === 1 ? 'secondary':'primary'}>
            { seatFindingAns.status === 1 ? 'Disable':'Enable'}
          </Button>:null
        }
        <Button size="small" onClick={toggleModalFn} variant="outlined" color="primary">Cancel</Button>
        <Button size="small" onClick={saveSeatFindingsAns} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
    {isLoading ? <PageLoader />:null}
    {modal ?
  <DeletePopUp
      modal={modal}
      toggleModalFn={() => setModal(false)}
      title={`${ seatFindingAns.status === 1 ? 'Disable':'Enable'} Seat`}
      content={<h4>Are you sure you want to  { seatFindingAns.status === 1 ? 'disable':'enable'}?</h4>}
      deleteRecordFn={() =>changeSeatStatus( seatFindingAns.status === 1 ? 0:1) }
      confirmText={ seatFindingAns.status === 1 ? 'Disable':'Enable'}
      confirmColor={seatFindingAns.status === 1 ? 'secondary':'primary'}
  />:null
}
    </>
  )
}

export default SeatDetails;
