import React, { useState, useEffect, Fragment } from 'react';
import { Paper } from '@material-ui/core';
import { FormSkeletonLoader, FormWrapper } from '../../Elements';
import { SeatDetails, CrewSeatConfig, AircraftTail } from '../components';
import { imgStoragePath } from '../../../constants';
import { pilotConfig } from '..';
import { globalGetService, globalPostService, globalStaticExportService } from '../../../globalServices';
import { checkApiStatus, downloadFileType, getLocalStorageInfo } from '../../../utils_v2';
import Header from '../components/Header';
import { ExportManu, PageLoader } from '../../../shared_elements';
import { trackActivity } from '../../../utils/mixpanel';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';

const WindowSeats = ({ params }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [formLoader, setFormLoader] = useState(true);
  const [currentFormDetail, setCurrentFormDetail] = useState({});
  const [pilotSeats, setPilotSeats] = useState(pilotConfig);
  const [seatConfig, setSeatConfig] = useState({ interior_config_details: [] });
  const [existingFinding, setExistingFinding] = useState({});
  const [seatarr, setSeatarr] = useState([]);
  const [windowArr, setWindowArr] = useState([]);
  const [seatWindFlag, setSeatWindFlag] = useState(1);
  const [selectedAllWindowSeats, setSelectedAllWindowSeats] = useState(false);
  const [seatFindingsQes, setSeatFindingQes] = useState({});
  const [seatFindingAns, setSeatFindingAns] = useState({findings:[],status: 0})
  const [seatDetails, setSeatDetails] = useState({ modal: false, data: [] });
  const [selectAllDisabled, setSelectAllDisabled] = useState(false);

  useEffect(() => {
    getCurrentFormDetail('formLoader')
    getSeatingConfig('skeletonLoader')
    getSeatFinding('skeletonLoader')
    getCrewSeatClasses()
    trackActivity('Project Management', {
      event_type: 'Page Visited',
      page_title: 'T008 Form'
    });
  }, []);
  const getCrewSeatClasses = (seatType) => {
    const isDisabled = existingFinding.seats?.some(findingData => (findingData.status === 0 && findingData.seat_number === seatType));
    const isSelected = seatarr.some(seat => seat.seat_number === seatType);
    const hasFinding = existingFinding.seats?.some(findingData => (findingData.seat_number === seatType && findingData.findings.length));

    return isDisabled && isSelected ? 'disabled-seat current'
      : isDisabled ? 'disabled-seat'
        : isSelected ? 'active current enabled-seat'
          : hasFinding ? 'has-finding findings-found enabled-seat'
            : 'enabled-seat';
  };
  const getCurrentFormDetail = (loaderType) => {
    if (loaderType) { setFormLoader(true) }
    globalGetService(`technical/workorder/${params.workOrderSlug}/forms/`, { form: params.formSlug })
      .then(response => {
        if (loaderType) { setFormLoader(false) }
        if (checkApiStatus(response)) {
          setCurrentFormDetail(response.data.data)
        }
      })
  }
  const onChangeFormStatus = (data) => {
    setLoading(true)
    globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/?asset_type=${params.type}`,data)
    .then(response => {
        setLoading(false)
        if(checkApiStatus(response)){
            getCurrentFormDetail('formLoader');
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            trackActivity('Project Management', {
                event_type: 'Form Status Updated',
                page_title: 'T008 Form',
                form_slug: params.formSlug ? params.formSlug : '',
                workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                status: data.status ? data.status: '',
                request_body: data ? data : '',
                success_msg: response.data.message
            });
        }else{
            enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            trackActivity('Project Management', {
                event_type: 'Form Status Update Failed',
                page_title: 'T008 Form',
                form_slug: params.formSlug ? params.formSlug : '',
                workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                status: data.status ? data.status: '',
                request_body: data ? data : '',
                error_source: 'Backend',
                error_msg: response.data.message
            });
        }
    });
}
const exportFormReport = (extension) => {
  enqueueSnackbar('T008 Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
  globalStaticExportService(`technical/workorder/${params.workOrderSlug}/interior-inspection-download/`, {download:extension})
  .then(response => {
      downloadFileType(response, (`T008_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), extension);
      closeSnackbar();
      trackActivity('Project Management', {
          event_type: 'File Exported',
          page_title: 'T008 Form',
          project_slug: params.project_slug ? params.project_slug : '',
          file_name: `T008_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`,
          file_extension: extension,
          success_msg: 'T008 Report Downloaded'
      })
  });
}
  const getSeatingConfig = (query, loaderType) => {
    if(loaderType === 'skeletonLoader'){setSkeletonLoader(true)}else{setLoading(true)}
    globalGetService(`technical/workorder/${params.workOrderSlug}/interior-inspection-config/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          setSeatConfig(response.data.data)
          if(loaderType === 'skeletonLoader'){setSkeletonLoader(false)}else{setLoading(false)}
        }
      })
  }
  const getSeatFinding = (query,loaderType) => {
    if(loaderType === 'skeletonLoader'){setSkeletonLoader(true)}else{setLoading(true)}
    globalGetService(`technical/workorder/${params.workOrderSlug}/seat-window-inspection-detail/`, query)
      .then(response => {
        if (checkApiStatus(response)) {
          setExistingFinding(response.data.data)
          if(loaderType === 'skeletonLoader'){setSkeletonLoader(false)}else{setLoading(false)}
        }
      })
  }
  const getSeatFindingQuestions = (seatType) => {
    setLoading(true);
    globalGetService(`technical/interior/${seatType === 1 ? 'seat-finding-questions' : 'window-finding-questions'}/`)
      .then(response => {
        if (checkApiStatus(response)) {
          setSeatFindingQes(response.data)
          setLoading(false);
        }
      })
  }
  const getSeatFindingsAns = (seatType, data) => {
    setLoading(true);
    globalPostService(`technical/workorder/${params.workOrderSlug}/${seatType === 1 ? 'get-seat-findings' : 'get-window-findings'}/`, seatType === 1 ? { seats: data } : { windows: data })
      .then(response => {
        if (checkApiStatus(response)) {
           setSeatFindingAns(response.data.data)
           setLoading(false);
        }
      })
  }
  const addSeatsFn = (seat) => {
    const disabledSeats = existingFinding.seats.filter(item => item.status === 0).map(item => item.seat_number);  
    const isSeatSelected = seatarr.some(item => item.seat_number === seat.seat_number);
    const isSeatDisabled = disabledSeats.includes(seat.seat_number); 
    if (isSeatSelected) {   
        setSeatarr(prevSeatArr => prevSeatArr.filter(item => item.seat_number !== seat.seat_number));      
    } else {     
        setSeatarr(prevSeatArr => [...prevSeatArr, seat]);
  
    }
  };
  
  const selectAllDisabledSeatsFn = () => {
    const disabledSeats = existingFinding.seats 
      .filter(seat => seat.status === 0) 
      .map(seat => ({
        seat_number: seat.seat_number,
        seat_type: seat.seat_type || '', 
        row_number: seat.row_number || 1, 
        interior_inspection_id: seat.interior_inspection || null
      }));
    setSeatarr(prevSeatArr => {
      const newSeats = disabledSeats.filter(seat => !prevSeatArr.some(item => item.seat_number === seat.seat_number));
      return [...prevSeatArr, ...newSeats];
    });
  };
  
  const disabledSeatsSelection = () => {
    setSelectAllDisabled(prev => !prev);
    if (!selectAllDisabled) {
      selectAllDisabledSeatsFn();
    } else {
      setSeatarr(prevSeatArr => prevSeatArr.filter(seat => !existingFinding.seats.some(disabledSeat => disabledSeat.status === 0 && disabledSeat.seat_number === seat.seat_number)));
    }
  };
    
  const selectAllWindowOrSeat = () => {
    let seatRange = [];
    let disabledSeats = existingFinding.seats ? existingFinding.seats.filter(findingData => (findingData.status === 0)).map(item => item.seat_number) : [];
    if (seatWindFlag === 1) {
      if (selectedAllWindowSeats) {
        setSeatarr([]);
        setSelectedAllWindowSeats(false);
      } else {
        let selectedSeats = [];
        seatConfig.interior_config_details.map((data, index) => {
          seatRange = range(data.starting_row, data.end_row);
          seatRange.map((seatNo, seatIndex) => {
            data.columns.map((colItem, index) => {
              colItem.map((col, colIndex) => {
                if (!disabledSeats.includes(seatNo + col)) {
                  selectedSeats.push({
                    seat_number: seatNo + col,
                    seat_type: data.seat_type,
                    row_number: parseInt(seatIndex + 1),
                    interior_inspection_id: data.id
                  })
                }
              })
            })
          })
        })
        let crewSeats = pilotSeats.filter(item => !disabledSeats.includes(item.seat_number));
        setSeatarr([...selectedSeats, ...crewSeats]);
        setSelectedAllWindowSeats(true);
      }
    } else {
      if (selectedAllWindowSeats) {
        setWindowArr([]);
        setSelectedAllWindowSeats(false);
      } else {
        let selectedWindows = seatConfig.interior_config_details.flatMap(data =>
          range(data.starting_row, parseInt(data.end_row + (data.row_diff || 0))).flatMap(seatNo => [
            { window_number: seatNo + 'R', row_number: parseInt(seatNo, 10), interior_inspection_id: data.id },
            { window_number: seatNo + 'L', row_number: parseInt(seatNo, 10), interior_inspection_id: data.id }
          ])
        );
        setWindowArr(selectedWindows);
        setSelectedAllWindowSeats(true);
      }
    }
  };

  const addWindowFn = (seat) => {
    setWindowArr(prevWindowArr => {
      return prevWindowArr.find(item => item.window_number === seat.window_number)
        ? prevWindowArr.filter(item => item.window_number !== seat.window_number)
        : [...prevWindowArr, seat];
    });
  };

  const updateFinding = (item, flag) => {
    setSeatFindingAns(prevSeatFindings => {
      const findings = Array.isArray(prevSeatFindings.findings) ? prevSeatFindings.findings : []; 
      return {
        ...prevSeatFindings,
        findings: flag
          ? [...findings, item]
          : findings.filter(finding => finding.id !== item.id)
      };
    });
  };  
  const range = (start, end) => Array(end - start + 1).fill().map((_, idx) => start + idx);
  let disabledSeat = existingFinding.seats?.find(item=>item.status === 0)
  return (
    <section className='project-forms-section-v'>
    { currentFormDetail && Object.keys(currentFormDetail).length ?
      <FormWrapper
        formInfo={currentFormDetail}
        hideAction={currentFormDetail?.current_form?.progress === 0 ? true:false}
        exportReportBtn={<ExportManu disabled={currentFormDetail?.current_form?.progress === 0 ? true: false}  exportReportFn={(file) => exportFormReport(file.extension)} title="Export Report" files={[{title:'PDF', extension: 'pdf', key:''},{title:'Doc', extension: 'docx', key:''}]} />}
        updateFormStatusFn={(data) => onChangeFormStatus(data)}
      />:formLoader ? <FormSkeletonLoader />:null}
      <>
        <Paper  className="technical-aircraft-froms-v">
        { skeletonLoader ? <Skeleton variant="rect" width={'100%'} height={480} />:
          <div className="t008-form-v">
            <Header skeletonLoader={isLoading} seatConfig={seatConfig} getCurrentFormDetail={getCurrentFormDetail} getResponseBack={()=>{getSeatingConfig();getSeatFinding();getCurrentFormDetail()}} getSeatingConfig={getSeatingConfig} setSeatConfig={setSeatConfig} setSeatDetails={setSeatDetails} seatarr={seatarr} windowArr={windowArr} seatWindFlag={seatWindFlag} selectedAllWindowSeats={selectedAllWindowSeats} setSeatWindFlag={setSeatWindFlag} setSeatarr={setSeatarr} setWindowArr={setWindowArr} selectAllWindowOrSeat={selectAllWindowOrSeat} getSeatFindingQuestions={getSeatFindingQuestions} getSeatFindingsAns={getSeatFindingsAns} disabledSeatsSelection={disabledSeatsSelection} selectAllDisabled={selectAllDisabled} disabledSeat={disabledSeat} currentFormDetail={currentFormDetail} />
            <div className="seats-container">
              {Object.keys(seatConfig).length && seatConfig.interior_config_details && seatConfig.interior_config_details.length ?
                <div className="seat-matrix" style={{ display: "flex" }}>
                  <CrewSeatConfig
                    seatWindFlag={seatWindFlag}
                    existingFinding={existingFinding}
                    seatarr={seatarr}
                    pilotSeats={pilotSeats}
                    addSeatsFn={addSeatsFn}
                  />
                  <div className="cabin-seats">
                    <img src={imgStoragePath + "aircraft-body.png"} className="cabin-image" />
                    {seatConfig.interior_config_details.map((data, interiorIndex) => {
                      let endRow = data.row_diff ? data.row_diff : 0;
                      let startRow = data.starting_row == 0 ? 1 : data.starting_row;
                      let seatRange = range(startRow, parseInt(data.end_row + endRow));
                      return (
                        seatRange.map((seatNo, seatIndex) =>
                          <ul key={seatIndex} className="list-inline seat-list">
                            <li className="list-inline-item">
                              <ul className="list-unstyled">
                                <li>
                                  <a
                                    className={windowArr.filter(window => window.window_number == (seatNo + 'R').toString()).length && [windowArr.filter(window => window.window_number == (seatNo + 'R').toString())[0]['window_number']].includes((seatNo + 'R').toString()) ? 'window window-selected' : existingFinding.windows && existingFinding.windows.filter(findingData => findingData.window_number == (seatNo + 'R').toString()).length && [existingFinding.windows.filter(findingData => findingData.window_number == (seatNo + 'R').toString())[0]['window_number']].includes((seatNo + 'R').toString()) ? 'has-finding  window' : 'window'}
                                    onClick={() => seatWindFlag===0 ? addWindowFn({ window_number: (seatNo + 'R'), row_number: (seatIndex + 1), interior_inspection_id: data.id }):''}
                                    style={seatWindFlag ? { 'opacity': '0.5', pointerEvents: 'none' } : { 'opacity': '1', pointerEvents: 'auto' }}
                                  >
                                  </a>
                                </li>
                                {data.columns.map((colItem, index) =>
                                  colItem.map((col, colIndex) => {
                                    let checkExistingFinding = existingFinding.seats ? existingFinding.seats.filter(findingData => (findingData.seat_number == seatNo + col)) : [];
                                    let disabledSeat = existingFinding.seats ? existingFinding.seats.filter(findingData => (findingData.status == 0 && findingData.seat_number == seatNo + col)) : [];
                                    let selectedSeat = seatarr.length ? seatarr.filter(seat => seat.seat_number == (seatNo + col)) : [];
                                    return (
                                      <li key={colIndex} className={((colItem.length - 1) == colIndex) ? "column-seperator" : ""}
                                        style={seatRange.indexOf(seatNo) > seatRange.indexOf(data.end_row) || seatRange.indexOf(seatNo) < seatRange.indexOf(data.starting_row) ? { opacity: 0, pointerEvents: 'none', visibility: 'hidden' } : {}}
                                      >
                                        <a
                                          style={seatWindFlag ? { opacity: 1, pointerEvents: 'auto' } : { opacity: '0.5', pointerEvents: 'none' }}
                                          className={(disabledSeat.length && [disabledSeat[0]['seat_number']].includes((seatNo + col)) && selectedSeat.length && [selectedSeat[0]['seat_number']].includes((seatNo + col))) ? 'disabled-seat current' : disabledSeat.length && [disabledSeat[0]['seat_number']].includes((seatNo + col)) ? 'disabled-seat' : selectedSeat.length && [selectedSeat[0]['seat_number']].includes((seatNo + col)) ? 'active current enabled-seat' : checkExistingFinding.length && checkExistingFinding[0].findings.length && [checkExistingFinding[0]['seat_number']].includes((seatNo + col)) ? 'has-finding findings-found enabled-seat' : 'enabled-seat'}
                                          onClick={() => seatWindFlag===1 ? addSeatsFn({ seat_number: (seatNo + col), seat_type: data.seat_type, row_number: (seatIndex + 1), interior_inspection_id: data.id }):null}
                                        > {seatNo} {col}  </a>
                                      </li>
                                    )
                                  }))
                                }
                                <li>
                                  <a
                                    className={windowArr.filter(window => window.window_number == (seatNo + 'L').toString()).length && [windowArr.filter(window => window.window_number == (seatNo + 'L').toString())[0]['window_number']].includes((seatNo + 'L').toString()) ? 'window window-selected' : existingFinding.windows && existingFinding.windows.filter(findingData => findingData.window_number == (seatNo + 'L').toString()).length && [existingFinding.windows.filter(findingData => findingData.window_number == (seatNo + 'L').toString())[0]['window_number']].includes((seatNo + 'L').toString()) ? 'has-finding  window' : 'window'}
                                    onClick={() => addWindowFn({ window_number: (seatNo + 'L'), row_number: (seatIndex + 1), interior_inspection_id: data.id })}
                                    style={seatWindFlag ? { 'opacity': '0.5', pointerEvents: 'none' } : { 'opacity': '1', pointerEvents: 'auto' }}
                                  >
                                  </a>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        )
                      )
                    })}
                  </div>
                  <AircraftTail />
                </div> :
                <p style={{ color: '#6f859b', textAlign: 'center', fontSize: '16px', marginTop: '30px' }}>Seating configuration not found</p>
              }
            </div>
          </div>}
        </Paper>
      </>
      {seatDetails.modal ?
        <SeatDetails
          isLoading={isLoading}
          seatarr={seatarr}
          windowArr={windowArr}
          seatWindFlag={seatWindFlag}
          seatDetails={seatDetails}
          seatFindingsQes={seatFindingsQes}
          seatFindingAns = {seatFindingAns}
          updateFinding={updateFinding}
          params={params}
          toggleModalFn={() => setSeatDetails({modal:false , data:null})}
          getCurrentFormDetail={getCurrentFormDetail}
          getResponseBack={()=>{getSeatingConfig();getSeatFinding();getCurrentFormDetail()}}
          setLoading={setLoading}
          disabledSeat={disabledSeat}
        /> : null
      }
      {isLoading ? <PageLoader />:null}
    </section>
  );
};
export default (WindowSeats);
