import FormTemplates from './form_templates/containers'
import ProjectListing from './projects/containers'
import ProjectDetail from './projects/containers/View'
import OprForm from './projects/containers/OprForm'
import ProjectUsers from './projects/containers/ProjectUsers'

import GeneralPictures from './t001/containers';
import DataPlates from './t002/containers';
import DocumentsChecklist from './t003/containers';
import ObservationChecklist from './t004/containers';
import Contacts from './t006/containers';
import PictorialFindings from './t007/containers';
import Fabrik3D from './t007/containers/Fabrik3D';
import Robberies from './t009/containers';
import TechnicalSpecification from './t005/containers';
import ProblemResolutionSheet from './t011/containers'
import PRSIssueDetail from './t011/containers/PRSIssueDetail'
import T014Nav from './t014/containers';

import BoreScopeInspection from './te001/containers';
import PowerPlantInventories from './te002/containers';
import GapReports from './te003/containers';
import BuildStandardEvaluation from './te004/containers'
import TableInspection from './te005/containers';
import MrClaimAndInvoiceReview from './te006/containers'
// Budget Management Routes
import { expenseManagementRoutes } from './budget_management/routes'

// Task Management Routes
import { taskManagementRoutes } from './task_management/routes'
import OprTracker from './trackers/containers/OprTracker';
import RoleBasedAccess from '../utils/RoleBasedAccess'
import requireAuth from '../utils/requireAuth'
import Workbook from './t013/containers';
import WindowSeats from './t008/containers/index';
export const projectsRoutes = [
    ...expenseManagementRoutes,
    ...taskManagementRoutes,
    {
        path:'/technical/opr/report',
        component:requireAuth(RoleBasedAccess(OprTracker,['project_management','opr_report','R'])),
        key:'OprTracker'
    },
    {
        path:'/technical/projects',
        component:requireAuth(ProjectListing),
        key:'ProjectListing'
    },
    {
        path:'/technical/form/templates',
        component:requireAuth(RoleBasedAccess(FormTemplates,['project_management','template','R'])),
        key:'FormTemplates'
    },
    {
        path:'/technical/project/view/:project_slug',
        component:requireAuth(ProjectDetail),
        key:'ProjectDetail'
    },
    {
        path:'/technical/project/view/:project_slug/project-opr-form',
        component:requireAuth(OprForm),
        key:'OprForm'
    },
    {
        path:'/technical/project/:project_slug/project-users',
        component:requireAuth(RoleBasedAccess(ProjectUsers,['project_management','users','R'])),
        key:'ProjectUsers'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t001',
        component:requireAuth(GeneralPictures),
        key:'GeneralPictures'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t002',
        component:requireAuth(DataPlates),
        key:'DataPlates'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T003',
        component:requireAuth(DocumentsChecklist),
        key:'DocumentsChecklist'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/t004',
        component:requireAuth(ObservationChecklist),
        key:'ObservationChecklist'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/v_t005',
        component:requireAuth(TechnicalSpecification),
        key:'TechnicalSpecification'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T006',
        component:requireAuth(Contacts),
        key:'Contacts'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/v1_t007',
        component:requireAuth(PictorialFindings),
        key:'PictorialFindings'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/v_t007',
        component:requireAuth(Fabrik3D),
        key:'Fabrik3D'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T009',
        component:requireAuth(Robberies),
        key:'Robberies'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T011',
        component:requireAuth(ProblemResolutionSheet),
        key:'ProblemResolutionSheet'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T011/:id',
        component:requireAuth(PRSIssueDetail),
        key:'PRSIssueDetail'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T013',
        component:requireAuth(Workbook),
        key:'DocumentsChecklist'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te001',
        component:requireAuth(BoreScopeInspection),
        key:'BoreScopeInspection'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te002',
        component:requireAuth(PowerPlantInventories),
        key:'PowerPlantInventories'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te003',
        component:requireAuth(GapReports),
        key:'GapReports'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te004',
        component:requireAuth(BuildStandardEvaluation),
        key:'BuildStandardEvaluation'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te005',
        component:requireAuth(TableInspection),
        key:'TableInspection'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/te006',
        component:requireAuth(MrClaimAndInvoiceReview),
        key:'MrClaimAndInvoiceReview'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/T014',
        component:requireAuth(T014Nav),
        key:'t014'
    },
    {
        path:'/:type/:aircraft_slug/technical/:workOrderSlug/forms/:formSlug/v_t008',
        component:requireAuth(WindowSeats),
        key:'WindowSeatsMapping'
    },
]
