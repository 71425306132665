import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Paper, Grid, Button } from '@material-ui/core'
import { FormWrapper, FormSkeletonLoader } from '../../Elements';
import { ExportManu, TableListComp, EmptyCollection, PageLoader, DeletePopUp } from '../../../shared_elements';
import { STableLoader } from '../../../shared_elements/loaders';
import FilterComponent from '../../../shared_elements/filter_component';
import { AddIssue, IssueList, IssueCountCard, Switcher, IssuesSearch, ImportViaSheet, PRSAnalytic, BulkOperation } from '../components';
import { checkApiStatus, removeEmptyKey, getLocalStorageInfo, downloadFileType,onCheckFormPermission } from '../../../utils_v2';
import { globalGetService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices';
import { prsHds, prsFilters, prsHdsCastleLake } from '../';
import { globalStaticExportService } from '../../../globalServices';
import { trackActivity } from '../../../utils/mixpanel';
import { browserHistory } from 'react-router';
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
import InvalidUrl from '../../../shared_elements/components/InvalidUrl';
import { castleAirInstance } from '../../../shared_elements/components';
const ProblemResolutionSheet = ({params, location}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [formLoader, setFormLoader] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [initialLoader, setInitialLoader] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(false);
    const [filter, setFilter] = useState({});
    const [currentFormDetail , setCurrentFormDetail] = useState(null);
    const [prsInfo, setPrsInfo] = useState({list:[], pagination:{}})
    const [prsCategories, setPrsCategories] = useState({items:null, list:[]});
    const [tags, setTags] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);
    const [assigneeList, setAssigneeList] = useState([]);
    const [createdByUsers, setCreatedByUsers] = useState([]);
    const [addIssue, setAddIssue] = useState({modal:false, data:null});
    const [mode, setMode] = useState('list');
    const [bulkOps, setBulkOps] = useState({type:'', ids:[]})
    useEffect(() => {
        getCurrentFormDetail('initial');
        getPRSListApi({page:1, per_page:10,...location.query}, 'skeletonLoader');
        getPRSCategories();
        getCreatedByUsers();
        getAssignedUsers();
        getAssigneeList();
        getTags();
    },[]);
    const getCurrentFormDetail = (initialLoad) => {
        setFormLoader(true);
        if(initialLoad){setInitialLoader(true)}
        globalGetService(`technical/${params.type === 'engine' ? 'engine-workorder' :'workorder'}/${params.workOrderSlug}/forms/`,{form:params.formSlug})
        .then(response => {
            if(initialLoad){setInitialLoader(false)}
            setFormLoader(false);
            if(checkApiStatus(response)){
                setCurrentFormDetail(response.data.data)
            }
        })
    }
    const getPRSListApi = (query, loaderType) => {
        if(loaderType === 'skeletonLoader'){ setSkeletonLoader(true)}else{setLoading(true)}
        globalGetService(`technical/workorder/${params.workOrderSlug}/gap-report/?asset_type=${params.type}&sort=created_at&sort_by=desc`,removeEmptyKey(query))
        .then(response => {
            if(loaderType === 'skeletonLoader'){ setSkeletonLoader(false)}else{setLoading(false)}
            if(checkApiStatus(response)){
                delete query.sort;
                delete query.sort_by;
                delete query.page;
                delete query.per_page;
                setPrsInfo(response.data.data);
                setFilter(query);
                browserHistory.push(`/${params.type}/${params.aircraft_slug}/technical/${params.workOrderSlug}/forms/${params.formSlug}/T011/`)
            }
        })
    }
    const getAssigneeList = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/project-users/?asset_type=${params.type}`)
        .then(response => {
            if (checkApiStatus(response)) {
                setAssigneeList(response.data.data)
            }
        })
    }
    const getCreatedByUsers = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/project-users/?asset_type=${params.type}`, {filter_created_by:true})
        .then(response => {
            if (checkApiStatus(response)) {
                setCreatedByUsers(response.data.data)
            }
        })
    }
    const getAssignedUsers = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/project-users/?asset_type=${params.type}`,{filter_assignee:true})
        .then(response => {
            if (checkApiStatus(response)) {
                setAssignedUsers(response.data.data);
            }
        })
    }
    const getPRSCategories = () => {
        globalGetService(`technical/workorder/${params.workOrderSlug}/gap-report-sections/?asset_type=${params.type}`)
        .then(response => {
            if(checkApiStatus(response)){
                setPrsCategories(response.data.data);
            }
        });
    }
    const getTags = () => {
        globalGetService(`technical/prs-lessor-tags/`)
        .then(response => {
            if(checkApiStatus(response)) {
                setTags(response.data.data);
            }
        })
    }
    const toggleBulkOps = (type, flag, id) => {
        if(flag){
            if(type === 'all'){
                setBulkOps({type:'',ids:prsInfo.list.map(item => item.id)});
            }else{
                setBulkOps({...bulkOps, ids: [...bulkOps.ids, id]})
            }
        }else{
            if(type === 'all'){
                setBulkOps({type:'',ids:[]});
            }else{
                setBulkOps({...bulkOps, ids: bulkOps.ids.filter(item => item !== id)})
            }
        }
    }
    const prsBulkFieldUpdate = (opsType,value) => {
        let payload = Object.assign({},{item_ids:bulkOps.ids, [opsType]:value});
        if(opsType === 'delete'){
            setBulkOps({...bulkOps,type:'delete'})
        }else if(opsType === 'to_section_id'){
            globalPostService(`technical/workorder/${params.workOrderSlug}/move-items/?asset_type=${params.type}`,payload)
            .then(response => {
                if(checkApiStatus(response)){
                    setBulkOps({type:'', ids:[]});
                    getPRSListApi({page:1, per_page:10}, 'pageLoader');
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    getPRSCategories();
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            })
        }else{
            
        // {item_ids:[],assignee_id:'', :priority:1,status:0, to_section_id:1}
            globalPostService(`technical/workorder/${params.workOrderSlug}/prs-multiple-update/?asset_type=${params.type}`, payload)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    setBulkOps({type:'', ids:[]});
                    getPRSListApi({page:1, per_page:10}, 'pageLoader');
                    getPRSCategories();
                    getCurrentFormDetail()
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                }
            });
        }
    }
    const onDeleteIssue = () => {
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/gap-reports/delete/?asset_type=${params.type}`, {gap_report_ids:bulkOps.ids})
        .then(response => {
            if(checkApiStatus(response)){
                setBulkOps({type:'', ids:[]});
                getPRSListApi({page:1, per_page:10}, 'pageLoader');
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getPRSCategories();
               getCurrentFormDetail()
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }
    const exportFormReport = (extension) => {
        enqueueSnackbar('T011 Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/workorder/${params.workOrderSlug}/gap-report-sections/?asset_type=${params.type}`, {download:extension, ...filter})
        .then(response => {
            downloadFileType(response, (`T011_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), extension);
            closeSnackbar();
            trackActivity('Project Management', {
                event_type: 'File Exported',
                page_title: 'T011 Form',
                project_slug: params.project_slug ? params.project_slug : '',
                file_name: `T011_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`,
                file_extension: extension,
                success_msg: 'Report exported Successfully'
            })
        });
    }
    const onChangeFormStatus = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/?asset_type=${params.type}`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                getCurrentFormDetail()
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }else{
                getCurrentFormDetail('formLoader');
                getCurrentFormDetail()
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
            }
        });
    }
    let filterOptions = Object.assign({}, prsFilters);
    filterOptions = {
        ...filterOptions,
        assignee: {
            ...filterOptions.assignee,
            options:assignedUsers
        },
        created_by: {
            ...filterOptions.created_by,
            options:createdByUsers
        },
        tags: {
            ...filterOptions.tags,
            options:tags
        },
        section: {
            ...filterOptions.section,
            options:prsCategories.list
        }
    }
    let formViewPermission = currentFormDetail?.list?.length && currentFormDetail?.list?.find(form => form.slug == currentFormDetail?.current_form?.slug)
    let formType = window.location?.pathname?.toUpperCase()?.includes(currentFormDetail?.current_form?.name)

    return(
        <section className='project-forms-section'>
            { currentFormDetail && Object.keys(currentFormDetail).length && formViewPermission && formType?
                <FormWrapper 
                    hideAction={currentFormDetail?.current_form.progress === 0 ? true:false}
                    formInfo={currentFormDetail} 
                    exportReportBtn={<ExportManu disabled={prsInfo?.list?.length ? false:true} exportReportFn={(file) => exportFormReport(file.extension)} title="Export Report" files={[{ title: "Excel", extension: "xls", key: "" }]} />}
                    updateFormStatusFn={(data) => onChangeFormStatus(data)}
                />:formLoader  ? <FormSkeletonLoader />:null
            }
            { skeletonLoader || initialLoader ? <STableLoader count={11} /> : 
              formType?  formViewPermission ?
            <div className='problem-resolution-sheet'>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item md={6}>
                        <IssueCountCard prsCategories={prsCategories} />
                    </Grid>
                    <Grid item md={6}>
                        <ul className='list-inline' style={{float:'right'}}>
                            <li className='list-inline-item'>
                                <Switcher mode={mode} onChangeMode={(mode)=>setMode(mode)} />
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                { mode === 'list' ?
                    <Fragment>
                        { skeletonLoader ? <STableLoader count={11} />:
                            <div>
                                <IssuesSearch 
                                    filter={filter}
                                    onChange={(keyParam, value) => setFilter({...filter, [keyParam]:value})} 
                                    onChangeStatus = {(status) => {getPRSListApi({...filter, ...status}, 'pageLoader')}}
                                    onApplyFilter = {(type) => {getPRSListApi({...filter, free_text:type === 'apply' ? filter?.free_text||'':''}, 'pageLoader')}}
                                />
                                <Paper style={{padding:'5px 10px', position:'relative'}} square>
                                    { bulkOps.ids.length ? 
                                        <BulkOperation 
                                            assigneeList={assigneeList}
                                            prsBulkFieldUpdate={prsBulkFieldUpdate}
                                            filterOptions={filterOptions}
                                            onCancelBulkOps={() => setBulkOps({type:'',ids:[]})}
                                        />:null 
                                    }
                                    <Grid container spacing={1} alignItems='center'>
                                        <Grid item md={9}>
                                            <FilterComponent 
                                                filter={filter} 
                                                filterMenu={filterOptions}
                                                getResponseBack={(applyFilter) => getPRSListApi({...applyFilter, page:1, per_page:prsInfo.pagination.per_page}, 'pageLoader')}
                                            />
                                        </Grid>
                                        <Grid item md={3}>
                                        { onCheckFormPermission(currentFormDetail) ?
                                            <ul className='list-inline' style={{float:'right'}}>
                                                <li className='list-inline-item'>
                                                    <Button onClick={() => setAddIssue({modal:true,data:null})} color='primary' variant='contained' size='small'>Add New Issue</Button>
                                                </li>
                                                <li className='list-inline-item'>
                                                    <ImportViaSheet getPRSCategories={getPRSCategories} getResponseBack={() => getPRSListApi({page:1, per_page:10}, 'pageLoader')} getCurrentFormDetail={getCurrentFormDetail}/>
                                                </li>
                                            </ul>: null} 
                                        </Grid>
                                    </Grid>
                                </Paper>
                                <TableListComp 
                                    style={{maxHeight: `${window.innerHeight-320}px`,overflow:'auto'}}
                                    bulkOperation={ onCheckFormPermission(currentFormDetail) ? true : false}
                                    bulkFlag={prsInfo.list.length && prsInfo.list.length === bulkOps.ids.length ? true:false}
                                    toggleBulkOps={(flag) => toggleBulkOps('all', flag)}
                                    heads={castleAirInstance? prsHdsCastleLake : prsHds}
                                    data={prsInfo.list.map((item, index) => 
                                        <IssueList 
                                            page={prsInfo?.pagination?.page||1}
                                            item={item}
                                            filter={filter}
                                            bulkOps={bulkOps}
                                            onChangeBulkOps={(flag) => toggleBulkOps('single', flag, item.id)}
                                            onDelete = {() => setBulkOps({type:'delete', ids:[item.id]})}
                                            currentFormDetail={currentFormDetail}
                                        />    
                                    )}
                                    onChangePage={(event, newPage) => {getPRSListApi({ page:newPage+1, per_page: prsInfo.pagination.per_page, ...filter}, 'pageLoader')}}
                                    onChangeRowsPerPage={(event) => getPRSListApi({ page:1, per_page: event.target.value, ...filter}, 'pageLoader')}
                                    noRecord={ prsInfo.list.length ? null:
                                        <EmptyCollection title="No records found" />
                                    }
                                    pagination={prsInfo.pagination}
                                />
                            </div>
                        }
                    </Fragment>:
                    <Fragment>
                        <PRSAnalytic 
                            prsCategories={prsCategories}
                        />
                    </Fragment>
                }
            </div>:
            isLoading? null: <RestrictedAccess /> 
                :
                <InvalidUrl  getResponseBack={()=>browserHistory.push(`/technical/project/view/${currentFormDetail?.project?.slug}`) }/>
                }
            
            { addIssue.modal?
                <AddIssue 
                    addIssue={addIssue}
                    toggleModalFn={() => setAddIssue({modal:false, data:null})}
                    getResponseBack={()=>getPRSListApi({page:1, per_page:10,sort:'created_at',sort_by:'desc', ...filter}, 'formLoader')}
                />:null
            }
            { bulkOps.type === 'delete' ?
                <DeletePopUp
                    modal={bulkOps.type === 'delete' ? true:false}
                    toggleModalFn={() => setBulkOps({type:'', ids:[]})}
                    title="Delete Issue(s)"
                    content={<h4>Are you sure you want to delete?</h4>}
                    deleteRecordFn={() => onDeleteIssue()}
                    confirmText="Delete"
                />:null
            }
            { isLoading ? <PageLoader />:null }
        </section>
    )
}
export default ProblemResolutionSheet;