import React, { useState, Fragment } from 'react';
import { browserHistory, withRouter } from 'react-router';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc';
import { Paper, Tooltip, TextField, Grid, Button, Dialog, DialogTitle, DialogContent, Divider, DialogActions, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import DocsFileList from './DocsFileList';
import UploadDocuments from './UploadDocuments';
import AddNewContent from './AddNewContent'
import EditContentItem from './EditContentItem'
import DocsCheckListHeader from './DocsCheckListHeader'
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { imgStoragePath } from '../../../constants';
import { DeletePopUp,EmptyCollection,PageLoader } from '../../../shared_elements';
import {arrayMoveImmutable} from 'array-move';
import { checkApiStatus, onCheckFormPermission, onCheckPermission } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import AddIssue from '../../t011/components/AddIssue';
import BugReportIcon from '@material-ui/icons/BugReport';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices';
import { castleAirInstance } from '../../../shared_elements/components';
import DataRoomUpRestriction from './DataRoomUpRestriction';
const DragHandle = sortableHandle(() => <span style={{paddingRight:'10px', display:'inline-block', cursor:'move'}}><DragIndicatorIcon /></span>);
const SortableItem = SortableElement(({questions, question, currentFormDetail,getCurrentFormDetail, getResponseBack , onUpdateDocCheckListAns,  onDelete, onEdit, onFileLink, onFilesUpload, onUnlinkCheckListFile, sortIndex,onAddIssue,params}) => {
    const [viewLessMore, setviewLessMore] = useState({ openChecklist: false, openRemarks: false,});
    const [selectedIssueId, setSelectedIssueId] = useState(null);
    const [modal, setModal] = useState(false);
    const [deleteissue, setDeleteissue] = useState({ modal: false, data:null, id:[] });
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [circularLoader, setCircularloader] = useState(false)
    const downloadFile = (file) => {
        axios.get(decodeURIComponent(file.path),{timeout: 600000, responseType : 'blob'})
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download',file.name);
            document.body.appendChild(link);
            link.click();
        })
    }  
    const onEditIssue = (issueId) => {
        let t011Slug = currentFormDetail?.list?.find(detail => detail?.name === 'T011')?.slug;
        setSelectedIssueId(issueId);
        window.open(`/${params.type}/${params.aircraft_slug}/technical/${params.workOrderSlug}/forms/${t011Slug}/T011/${issueId}`,'_blank');
    };
    const onDeleteIssue = () => {
        globalDeleteService(`technical/workorder/${params.workOrderSlug}/gap-reports/delete/?asset_type=${params.type}`, {gap_report_ids:deleteissue.id})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteissue({id:[]});
                enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack();
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            }
        });
    }

    return(
        <div>
        <Grid container alignItems='center' spacing={0} className='t003-document-sorting'>
            <Grid item md={castleAirInstance?5:6}>
                <div className='drag-handler-blk'>
                    { onCheckFormPermission(currentFormDetail) && questions.length > 1 ?
                        <DragHandle/>:null
                    }
                    <div>
                        <h4><span>{question.name}</span> </h4>
                        { onCheckFormPermission(currentFormDetail) ?
                            <ul className="list-inline spacing-list">
                                <li className="list-inline-item" onClick={() => onFilesUpload(question)}>
                                    <span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
                                </li>
                                {question.last_used_folder_uuid ?
                                <li className="list-inline-item" onClick={() => onFileLink(question)}>
                                    <span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
                                </li> : null}
                                {castleAirInstance && question?.issues?.length ?
                                <li className="list-inline-item" onClick={() => setModal({modal:true})} >
                                  <span className="flex-centered" ><BugReportIcon  color='primary' fontSize='small' />View Issues({question?.issues?.length})</span>
                                </li>:null
                             }

                            </ul>:null
                        }
                        { question.files ?
                            <div className='checklist-files'>
                                <ul className='list-inline'>
                                    {question.files.map((file) => 
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{file.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon  color="primary" /></a>
                                                { onCheckFormPermission(currentFormDetail) ?
                                                    <CloseIcon onClick={() => onUnlinkCheckListFile(question, file.id)} color='secondary' fontSize='small' />:null
                                                }
                                            </span>
                                        </li>
                                    )}
                                </ul>
                            </div>:null
                        }
                        {question.notes?
                        <p className='question-notes'>
                          <span>Notes: 
                        {question.notes ? question.notes?.trim().length > 50 ? viewLessMore.openChecklist ? question.notes:question.notes.slice(0, 47)+' ... ': question.notes :''}
                        {question.notes?.trim().length > 50 ?
                        <Fragment>
                        { viewLessMore.openChecklist ? <span style={{color:'#2e0fff', fontSize:'13px', textDecoration:'underline', cursor:'pointer'}} onClick={() => setviewLessMore((prev)=>({ ...prev, openChecklist: false,}))}> Show Less</span>: <span style={{color:'#2e0fff', fontSize:'13px', textDecoration:'underline', cursor:'pointer'}} onClick={() => setviewLessMore((prev)=>({ ...prev, openChecklist: true}))}> Show More</span>}
                        </Fragment>:null
                        }
                        </span>
                        </p>:null
                        }
                    </div>
                </div>
            </Grid>
            <Grid item md={2}>
                <ul className="list-inline spacing-list" style={{display:currentFormDetail?.current_form?.status?.id==1 ?'none':''}}>
                    {castleAirInstance ?
                    <li className="list-inline-item" style={{cursor: onCheckFormPermission(currentFormDetail) ? 'pointer':'default'}} onClick={(e) => { onCheckFormPermission(currentFormDetail) ? onUpdateDocCheckListAns({...question, value: question.value === 4 ? null : 4}):e.preventDefault() } }>
                        <img src={`${imgStoragePath}${question.value && question.value === 4 ? 'redo_color.png':'redo_gray.png'}`} alt="" />
                    </li>:null}
                    <li className="list-inline-item" style={{cursor: onCheckFormPermission(currentFormDetail) ? 'pointer':'default'}} onClick={(e) => { onCheckFormPermission(currentFormDetail) ? onUpdateDocCheckListAns({...question, value: question.value === 1 ? null : 1}):e.preventDefault()}}>
                        <img src={`${imgStoragePath}${question.value === 1 ? 'tick_color.png':'tick_gray.png'}`} alt="" />
                    </li>
                    <li className="list-inline-item" style={{cursor: onCheckFormPermission(currentFormDetail) ? 'pointer':'default'}} onClick={(e) => { onCheckFormPermission(currentFormDetail) ? onUpdateDocCheckListAns({...question, value: question.value === 2 ? null : 2 }):e.preventDefault() } }>
                        <img src={`${imgStoragePath}${question.value && question.value === 2 ? 'cross_color.png':'cross_gray.png'}`} alt="" />
                    </li>
                    <li className="list-inline-item" style={{cursor: onCheckFormPermission(currentFormDetail) ? 'pointer':'default'}} onClick={(e) => { onCheckFormPermission(currentFormDetail) ? onUpdateDocCheckListAns({...question, value: question.value === 3 ? null : 3}):e.preventDefault() } }>
                        <img src={`${imgStoragePath}${question.value && question.value === 3 ? 'na_color.png':'na_gray.png'}`} alt="" />
                    </li>
                </ul>
            </Grid>
            <Grid item md={castleAirInstance?5:4}>
                <Grid container spacing={1} alignItems='center'>
                    <Grid item md={castleAirInstance?8:10}>       
                    <div style={{width:'280px'}}>
                        {question.remarks ? question.remarks?.trim().length > 50 ? viewLessMore.openRemarks ? question.remarks:question.remarks.slice(0, 47)+' ... ': question.remarks :''}
                        {question.remarks?.trim().length > 50 ?
                        <Fragment>
                        { viewLessMore.openRemarks  ? <span style={{color:'#2e0fff', fontSize:'13px', textDecoration:'underline', cursor:'pointer'}} onClick={() => setviewLessMore((prev)=>({ ...prev, openRemarks: false}))}> Show Less</span>: <span style={{color:'#2e0fff', fontSize:'13px', textDecoration:'underline', cursor:'pointer'}} onClick={() => setviewLessMore((prev)=>({ ...prev, openRemarks: true, openRemarksId: sortIndex }))}> Show More</span>}
                        </Fragment>:null
                    }
                    </div>
                    </Grid>
                    <Grid item md={castleAirInstance?4:2}>
                        { onCheckFormPermission(currentFormDetail) ?
                            <ul className='list-inline flex-centered' style={{float:'right'}}>
                             {castleAirInstance ?
                                <li className='list-inline-item'>                                 
                                        <Button onClick={() => onAddIssue(question)} variant='outlined' color='primary' size='small' style={{fontSize:'10px'}} >Add Issue</Button>
                                </li>:null
                             }
                                <li className='list-inline-item'>
                                    <Tooltip title='Edit Checklist' arrow>
                                        <EditIcon style={{ cursor: "pointer" }} onClick={() => onEdit(question)} color='primary' fontSize='small' />
                                    </Tooltip>
                                </li>
                                <li className='list-inline-item'>
                                    <Tooltip title='Delete Checklist' arrow>
                                        <DeleteOutlineIcon style={{ cursor: "pointer" }} onClick={() => onDelete(question.id)} color='secondary' fontSize='small' />
                                    </Tooltip>
                                </li>
                            </ul>:null
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {modal ?
                <Dialog
                    open={modal}
                    onClose={() => setModal(false)}
                    className='console-management-modal'
                    maxWidth="md"
                >
                    <DialogTitle id="scroll-dialog-title"> Issue(s) </DialogTitle>
                    <DialogContent dividers={true}>
                        <div style={{ minWidth: '520px' }}>
                            
                                <Table className='mui-table-format' stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                        <TableCell>Unique Code</TableCell>
                                        <TableCell>Issue Title</TableCell>
                                        <TableCell style={{float:'right'}}>Action(s)</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {question.issues.map((issue) =>
                                    <TableBody>
                                        <TableRow>
                                        <TableCell>{issue.unique_code}</TableCell>
                                        <TableCell>{issue.title}</TableCell>
                                        <TableCell>
                                        <ul className="list-inline" style={{ float: 'right' }}> 
                                               <Tooltip placement="bottom-end" title='Edit Issue' arrow>
                                                    <li className="list-inline-item">
                                                            <EditIcon style={{ marginTop: "5px", cursor: "pointer" }} onClick={()=>onEditIssue(issue.id)} color="primary" fontSize="small" />
                                                    </li>
                                                </Tooltip>   

                                                <Tooltip placement="bottom-end" title='Delete Issue' arrow>
                                                    <li className="list-inline-item">
                                                            <DeleteOutlineIcon style={{ marginTop: "5px", cursor: "pointer" }} onClick={() =>setDeleteissue({modal:true,data:null,id:[issue.id]})} color="secondary" fontSize="small" />
                                                    </li>
                                                </Tooltip>
                                            </ul>
                                        </TableCell>
                                        </TableRow>
                                    </TableBody> )}
                                </Table>
                           
                            {!question.issues?.length ? <div style={{ textAlign: 'center', border: 'dotted', color: '#d2e1e7', padding: '50px' }}><EmptyCollection title={<h4 style={{ color: '#a0bdc8' }}>No Issues found</h4>} /></div> : null}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setModal(false)} color="primary" size="small" variant="outlined">Close</Button>
                    </DialogActions>
                </Dialog> : null
            }
            {deleteissue.modal ?
                <DeletePopUp
                    modal={deleteissue.modal}
                    title='Delete Issue'
                    content={<p style={{marginBottom:'13px'}}>Are you sure, you want to Delete Issue ?<br/><p style={{fontSize:'12px'}}>Info: Issue will be removed from T011</p></p>}
                    toggleModalFn={() => setDeleteissue({ modal: false, data: null })}
                    deleteRecordFn={onDeleteIssue}
                    circularloader={circularLoader}
                /> : null
            }

        </div>
    )
});
const SortableList = SortableContainer(({questions,getResponseBack, currentFormDetail, onUpdateDocCheckListAns, onDelete, onEdit, onFileLink, onFilesUpload, onUnlinkCheckListFile,onAddIssue,params}) => {
    return (
        <div className='docs-checklist-body'>
            {questions.map((question, index) => {
                return (
                    <SortableItem
                        params={params}
                        questions={questions}
                        key={`item-${index}`}
                        sortIndex={index}
                        index={index}
                        onAddIssue={onAddIssue}
                        question={question}
                        currentFormDetail={currentFormDetail}
                        onDelete={onDelete}
                        onUpdateDocCheckListAns={onUpdateDocCheckListAns}
                        onEdit={onEdit}
                        onFileLink={onFileLink}
                        onFilesUpload={onFilesUpload}
                        onUnlinkCheckListFile={onUnlinkCheckListFile}
                        getResponseBack={getResponseBack}
                    />
                )
            })}
        </div>
    );
});
const DocumentAnswer = ({params, currentFormDetail,getCurrentFormDetail, subSections, documentCheckList, sectionId,getResponseBack}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [isLoading, setLoading] = useState(false);
    const [subSection, setSubSection] = useState(subSections);
    const [deleteItem, setDeleteItem] = useState({modal:false,id:null});
    const [editItem, setEditItem] = useState({modal:false, data:null, itemType:'question', subSectionId:null});
    const [fileLinkInfo, setFileLinkInfo] = useState({modal:false, checkList:null})
    const [addIssues, setAddIssue] = useState({modal:false, data:null,checklist_id:null});
    const [uploadFilesInfo, setUploadFilesInfo] = useState({modal:false, checklist:null})
    const [noRecordsModal, setNoRecordsModal] = useState({modal:false})
    const onSortEnd = ({oldIndex, newIndex}) => {
        setSubSection({...subSection,questions_answers:arrayMoveImmutable(subSection.questions_answers, oldIndex, newIndex)})
        updateQuestionsSort(arrayMoveImmutable(subSection.questions_answers, oldIndex, newIndex));
    };
    const onDeleteQuestion = () => {
        setLoading(true);
        globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/document-checklists/${deleteItem.id}/?asset_type=${params.type}`)
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                setDeleteItem({modal:false, id:null});
                setSubSection({...subSection,questions_answers:subSection.questions_answers.filter(qus => qus.id !== deleteItem.id)});
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                getResponseBack();
                getCurrentFormDetail()
                trackActivity('Project Management', {
                    event_type:'Question Deleted',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    item_id: deleteItem.id ? deleteItem.id : '',
                    success_msg: response.data.message
                });
            }else{
                enqueueSnackbar(response.data.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type:'Question Delete Failed',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    item_id: deleteItem.id ? deleteItem.id : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                });
            }
        })
    }
    const onAddNewCheckList = (data) => {
        setSubSection({...subSection, questions_answers:[...data,...subSection.questions_answers]})
        getResponseBack();
    }
    const updateQuestionsSort = (checklists) => {
        setLoading(true);
        globalPutService(`technical/v2/workorder/${params.workOrderSlug}/document-section-sort/checklists/?asset_type=${params.type}`, checklists.map((qus,index) => {return{id:qus.id, order:index+1}}))
        .then(response => {
            setLoading(false);
            if(checkApiStatus(response)){
                enqueueSnackbar('Checklist Sorting updated successfully', { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                getResponseBack();
                trackActivity('Project Management', {
                    event_type: 'Checklist display order update Success',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    request_body: checklists  &&checklists.map((qus,index) => {return{id:qus.id, order:index+1}}),
                    success_msg: 'Checklists display order update Successful'
                });
            }else{
                enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                trackActivity('Project Management', {
                    event_type: 'Checklist display order update Failed',
                    page_title: 'T003 Form',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    request_body: checklists  &&checklists.map((qus,index) => {return{id:qus.id, order:index+1}}),
                    error_source:'Backend',
                    error_msg: response.data.message
                });
            }
        })
    }
    const onEditCheckList = (data) => {
        setSubSection({...subSection, questions_answers:subSection.questions_answers.map(qus => qus.id !== data.id ? qus : data)});
        setEditItem({modal:false, data:null, itemType:'question', subSectionId:null})
        getResponseBack();
        getCurrentFormDetail()
    }
    const onUpdateDocCheckListAns = (data) => {
        setLoading(true)
        if(data.value === 1 ||data.value === 4|| data.value===null || (data.remarks && data.remarks.trim().length)){
            globalPutService(`technical/v2/workorder/${params.workOrderSlug}/document-checklist/${data.id}/?asset_type=${params.type}`, data)
            .then(response => {
                if(checkApiStatus(response)){
                    enqueueSnackbar(response.data.message, { variant: 'success',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    setSubSection({...subSection, questions_answers:subSection.questions_answers.map(item => item.id !== data.id ? item:data)})
                    getResponseBack();
                    getCurrentFormDetail()
                    setLoading(false)
                    trackActivity('Project Management', {
                        event_type: 'Document Checklist answer update Success',
                        page_title: 'T003 Form',
                        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                        request_body: data ? data : '',
                        sub_section_id:subSection.id ? subSection.id : '',
                        item_type:'Question',
                        success_msg: response.data.message
                    });
                }else{
                    enqueueSnackbar(response.data.message, { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
                    trackActivity('Project Management', {
                        event_type: 'Document Checklist answer update Failed',
                        page_title: 'T003 Form',
                        workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                        request_body: data ? data : '',
                        item_type:'Question',
                        error_source: 'Backend',
                        sub_section_id:subSection.id ? subSection.id : '',
                        error_msg: response.data.message
                    });
                }
            })
        }else{
            setEditItem({modal:true,data:data,itemType:'question', subSectionId:subSection.id});
            enqueueSnackbar('Remarks is required', { variant: 'error',anchorOrigin:{horizontal:'right', vertical:'top'}})
            setLoading(false)
            trackActivity('Project Management', {
                event_type: 'Document Checklist answer update Failed',
                page_title: 'T003 Form',
                workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                request_body: data ? data : '',
                item_type:'Question',
                error_source: 'Frontend',
                sub_section_id:subSection.id ? subSection.id : '',
                error_msg: 'Remarks is required'
            });
        }
    }
    const updateCheckListFiles = (data) => {
        setSubSection({...subSection, questions_answers:subSection.questions_answers.map(item => item.id !== data.id ? item:data)})
    }
    const onUnlinkCheckListFile = (checklist, fileId) => {
        let payload = {
            delete:true,
            file_ids: checklist.files.map(file => file.id).filter(item => item !== fileId).join(','),
        };
        globalPutService(`technical/v2/workorder/${params.workOrderSlug}/document-checklists/${checklist.id}/?asset_type=${params.type}`, payload)
        .then(response => {
            if(checkApiStatus(response)){
                updateCheckListFiles(response.data.data);
                getResponseBack()
            }
        });
    }
    const checkRecordsPermission =(question)=>{
        if(onCheckPermission('records','data_room','UP')){
            setUploadFilesInfo({modal:true, checklist:question})
        } else{
            setNoRecordsModal({modal:true, checklist:question})
        }
    }
    return(
        <div className="t003-docs">
            { onCheckFormPermission(currentFormDetail) ?
                <ul className='list-inline flex-centered' style={{marginBottom:'8px'}}>
                    <li className='list-inline-item'>
                        <AddNewContent 
                            subSectionId={subSection.id} 
                            addType={'question'} 
                            getResponseBack={onAddNewCheckList} 
                            documentCheckList={documentCheckList} 
                            sectionId={sectionId}
                            questionList={subSection.questions_answers}
                        />
                    </li>
                </ul>:null
            }
            <DocsCheckListHeader />
            {subSection?.questions_answers?.length ? 
                <SortableList 
                    helperClass='sortableHelper' 
                    params={params}
                    questions={subSection.questions_answers} 
                    getResponseBack={getResponseBack}
                    currentFormDetail={currentFormDetail}
                    onSortEnd={onSortEnd} 
                    onDelete={(id) => setDeleteItem({modal:true, id:id})}
                    onUpdateDocCheckListAns={onUpdateDocCheckListAns}
                    onEdit={(question) => setEditItem({modal:true, data:{...question,sub_section_id:subSection.id, sectionId:sectionId}, itemType:'question'})}
                    onFileLink={(question) => setFileLinkInfo({modal:true, checkList:question})}
                    onFilesUpload={(question) =>checkRecordsPermission(question)}
                    onUnlinkCheckListFile={onUnlinkCheckListFile}
                    useDragHandle
                    onAddIssue={(question) => {setAddIssue({ modal: true, data: question, checklist_id: question.id });}}                
                />:
                <div className='no-data'>No Checklist Found</div>
            }
            { deleteItem.modal ?
                <DeletePopUp
                    modal={deleteItem.modal}
                    toggleModalFn={() => setDeleteItem({modal:false, id:null})}
                    title={`Delete`}
                    content={<h4>Are you sure you want to Delete?</h4>}
                    deleteRecordFn={() => onDeleteQuestion()}
                />:null
            }
            {editItem.modal ?
                <EditContentItem 
                    editItem={editItem}
                    toggleModalFn={() => setEditItem({modal:false, itemType:'', data:null, sectionId:null, subSectionId:null})}
                    getResponseBack={onEditCheckList}
                    documentCheckList={documentCheckList} 
                    questionList={subSection.questions_answers}
                    currentFormDetail={currentFormDetail}
                />:null
            }
            { fileLinkInfo.modal ?
                <DocsFileList 
                    fileLinkInfo={fileLinkInfo}
                    toggleModalFn={() => setFileLinkInfo({modal:false, checkList:null})}
                    getResponseBack={updateCheckListFiles}
                />:null
            }

            { uploadFilesInfo.modal ?
                <UploadDocuments 
                    uploadFilesInfo={uploadFilesInfo}
                    toggleModalFn={() => setUploadFilesInfo({modal:false, checklist:null})}
                    updateCheckListFiles={updateCheckListFiles}
                    getResponseBack={getResponseBack}
                />:null
            }
            { addIssues.modal?
                <AddIssue
                  addIssue={addIssues}
                  toggleModalFn={() => setAddIssue({modal:false, data:null})}
                  getResponseBack={()=>documentCheckList}
                  checklist_id={addIssues.checklist_id}
                  />:null
            }
             {isLoading ? <PageLoader />:null}
            {
                noRecordsModal?.modal ?
                <DataRoomUpRestriction 
                toggleModalFn={()=>setNoRecordsModal({modal:false})}
                handleFileUpload={()=>{setNoRecordsModal({modal:false});setUploadFilesInfo({modal:true, checklist:noRecordsModal.checklist, onlyProjects:true})}}
                />
                :null
            }
        </div>
    )
}
export default withRouter(DocumentAnswer);