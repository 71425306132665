import React, {Fragment} from 'react';
import { withRouter } from 'react-router';
import { Avatar, Chip, Tooltip } from '@material-ui/core';
import { CircularProgress } from '../../../shared_elements';
import EditIcon from '@material-ui/icons/Edit';
import { dateTransformAllSet, onCheckPermission } from '../../../utils_v2';
import { projectsSelfPermission } from '..';
const FormList = ({project, form, onAssignEngineer, engineerProfile, onRedirection, setDeleteEngModal, asset, workOrderSlug}) => {
  return(
    <tr onClick={onRedirection} style={{cursor:'pointer'}}>
      <td style={{width: '300px'}}>
        <div className="flex-centered form-progress">
          <CircularProgress value={form.progress ? form.progress:0} />
          <div className='form-name'>
            <p>Form Type</p>
            <h4>{`${form.name}-${form.title}`}</h4>
          </div>
        </div>
      </td>
      <td>
        <p>Status</p>
        <h4>{form.status.label}</h4>
      </td>
      <td>
        <p>Last Activity</p>
        <h4>{dateTransformAllSet(form.updated_at)}</h4>
      </td>
      <td align="right">
        <p>Form User(s)</p>
        {form.engineers?.length ?
          <h4 style={{ cursor: 'pointer' }} className="user-info" onClick={(e) => { e.preventDefault(); e.stopPropagation(); engineerProfile(form.engineer.slug) }}>
            {form.engineers.map((item) =>
             projectsSelfPermission('form','U', project, project?.formPermission?.update?.value||null)  && (project?.status?.id && ![0,7,8].includes(project.status.id)) ?
              <span>
                <Chip size='small' label={<Tooltip title={item?.name} arrow placement='top'>
                  <span style={{ fontSize: '11px' }}>{item?.name}</span>
                </Tooltip>
                } style={{ background: '#fff', margin: '0px 4px 4px 0px', maxWidth: '206px' }} onDelete={(e) => setDeleteEngModal({ data: item, modal: true, formSlug: form?.slug , assetType:asset?.type, workOrderSlug:workOrderSlug})} />
              </span>
              : 
              <span><Chip size='small' label={<Tooltip title={item?.name} arrow placement='top'>
                <span style={{ fontSize: '11px' }}>{item?.name}</span>
              </Tooltip>
              } style={{ background: '#e7e7e7', margin: '0px 4px 4px 0px', maxWidth: '206px' }} /></span>
            )}
          </h4>
          : null
        }
        { onCheckPermission('project_management','workorder','U') && (project?.status?.id && ![0,7,8].includes(project.status.id)) ?<span style={{cursor:'pointer', color:"#3f51b5"}} onClick={(e) => {e.stopPropagation(); e.preventDefault(); onAssignEngineer()}}>Assign</span>:null}
      </td>
    </tr>
  )
}
export default withRouter(FormList);
