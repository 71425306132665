import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, CircularProgress, Paper, Tooltip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { regexConstants } from '../../../constants/regEx';
import { nextChar, checkApiStatus } from '../../../utils_v2';
import { configObj } from '../';
import { DeletePopUp } from '../../../shared_elements';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { globalDeleteService, globalPostService } from '../../../utils_v2/globalApiServices';
import { useSnackbar } from 'notistack';

const SetSeatsConfig = ({ getResponseBack, getCurrentFormDetail, seatConfig, params, disablebtn }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formSubmitLoader, setFormSubmitLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [configList, setConfigList] = useState(seatConfig);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteIds, setDeleteIds] = useState({ deleteIndex: '', data: {} });
  const [sectionError, setSectionError] = useState([]);
  const [pltConstants, setPltConstants] = useState()
  useEffect(() => {
    if (seatConfig.length > 0) {
      setConfigList(seatConfig);
    } else {
      setConfigList(configObj);
    }   
     getGlobalConstant();
  }, [seatConfig]);
  const getGlobalConstant = () => {
    globalPostService('console/get-constants/', { constant_types: ['pax_seat_type'] })
      .then(response => {
        if (checkApiStatus(response)) {
          setPltConstants(response.data.data)
        }
      })
  }

  const setSeatingConfig = (sections) => {
    let sectionError = [];
    let sectionErrorFlag = true;
    const checkForOverlapSeat = (section, sectionIndex) => {
      
        let startRow = parseInt(section.starting_row);
        let endRow = parseInt(section.end_row);
        let lastSecEndRow = parseInt(sections[sectionIndex - 1]?.end_row);
        if (startRow > endRow) {
          return 'Start Row cannot be greater then End Row'
        } else if (lastSecEndRow > startRow || lastSecEndRow > endRow && sectionIndex !== 0) {
          return 'Section row number cannot overlap'
        } else {
          return ''
        }
    }
    const checkSeatNumber = (section, sectionIndex) => {
      let mergedArray = [].concat(...section.columns);
      if (mergedArray.length === 0) {
        return 'Please add Seat Number'
      } else if (mergedArray.length !== mergedArray.filter(item => item.trim()).length) {
        return 'Please enter the Seat Number'
      } else if (mergedArray.length !== new Set(mergedArray).size) {
        return 'Please enter unique Seat Number'
      } else {
        return ''
      }
    }
    const checkStartRowNumber = (section, sectionIndex) => {
      if (section.starting_row.toString().trim().length) {
        return ''
      } else {
        return 'Please enter the Starting Row Number'
      }
    }
    const checkEndRowNumber = (section, sectionIndex) => {
      if (section.end_row.toString().trim().length) {
        return ''
      } else {
        return 'Please enter the End Row Number'
      }
    }
    sections.map((section, sectionIndex) => {
      sectionError = [...sectionError, {
        'seat_type': section.seat_type ? '' : 'Please select Class',
        'unique_seat': checkSeatNumber(section, sectionIndex),
        'unique_number': section.starting_row && section.end_row ? checkForOverlapSeat(section, sectionIndex) : '',
        'start_empty_number': checkStartRowNumber(section, sectionIndex),
        'end_empty_number': checkEndRowNumber(section, sectionIndex)
      }];
    });
    sectionError.map(data => {
      if (!Object.keys(data).every((k) => { return data[k] === '' })) {
        sectionErrorFlag = false
      }
    });
    if (sectionErrorFlag) {
      setFormSubmitLoader(true)
      globalPostService(`technical/workorder/${params.workOrderSlug}/interior-inspection-config/`, configList)
        .then(response => {
          setFormSubmitLoader(false)
          if (checkApiStatus(response)) {
            setModal(false)
            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            getResponseBack('skeletonLoader')
            getCurrentFormDetail()
          } else {
            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
    } else {
      setSectionError(sectionError);
    }
  }

  const onremoveSeatConfigRow = (timestamp) => {
    if (deleteIds.data.id) {
      globalDeleteService(`technical/workorder/${params.workOrderSlug}/interior-section/${deleteIds.data.id}/delete-interior-section/?${timestamp = new Date().getTime()}`, {})
        .then(response => {
          if (checkApiStatus(response)) {
            getResponseBack()
            enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          } else {
            enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
          }
        })
    } else {
      setConfigList(configList.filter((_, sectionIndex) => sectionIndex !== deleteIds.deleteIndex))
    }
    setDeleteModal(false);
    setDeleteIds({ deleteIndex: '', data: {} })
  };

  const onFieldChange = (keyParam, value, sectionIndex) => {
    setConfigList(
      configList.map((section, index) =>
        index === sectionIndex ? { ...section, [keyParam]: value } : section
      )
    );
  };

  const onAddConfigSection = () => {
    setConfigList([...configList, ...configObj]);
  }

  const onRemoveConfigSection = (section, sectionIndex) => {
    setDeleteModal(true);
    setDeleteIds({ deleteIndex: sectionIndex, data: section })
  };

  const onRemoveColumnSeat = (colIndex, sectionIndex) => {
    setConfigList(configList.map((section, index) =>
      index === sectionIndex ? {
        ...section,
        columns: section.columns.map((column, cIndex) =>
          cIndex === colIndex ? column.slice(0, -1) : column
        )
      } : section
    )
    );
  };

  const onAddColumnSeat = (colIndex, sectionIndex) => {
    const section = configList[sectionIndex];
    const columns = section.columns;
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let highestSeatLetter = '';
    columns.forEach(column => {
      column.forEach(seat => {
        if (alphabet.includes(seat)) {
          if (alphabet.indexOf(seat) > alphabet.indexOf(highestSeatLetter)) {
            highestSeatLetter = seat;
          }
        }
      })
    })

    const nextSeatLetter = nextChar(highestSeatLetter);

    if (section.columns[colIndex].length < 4) {
      setConfigList(configList.map((sec, index) =>
        index === sectionIndex ? {
          ...sec,
          columns: sec.columns.map((col, cIndex) =>
            cIndex === colIndex ? [...col, nextSeatLetter] : col
          )
        } : sec
      ))
    }
  }

  const onEditColumnSeatNo = (value, seatIndex, colIndex, sectionIndex) => {
    setConfigList(configList.map((section, sIndex) =>
      sIndex === sectionIndex ? {
        ...section,
        columns: section.columns.map((column, cIndex) =>
          cIndex === colIndex ? column.map((seat, sIndex) => sIndex === seatIndex ? value : seat) : column
        )
      } : section
    )
    );
  };

  const onUpdateError = (key, index) => {
    if (sectionError[index]) {
      const updatedErrors = [...sectionError];
      updatedErrors[index] = { ...updatedErrors[index], [key]: '' };
      setSectionError(updatedErrors);
    }
  };


  return (
    <>
      <span onClick={() => setModal(disablebtn?false:true) && setConfigList(configList?.length ? configList : configObj)}>
        <Button style={{ color:!disablebtn?'white':'rgba(255,255,255,0.4)',border:!disablebtn?'groove white':'groove rgba(255,255,255,0.4)',fontSize:'12px', cursor:disablebtn?'not-allowed':'' }} size='small' variant='outlined' >
          {Object.keys(seatConfig).length  && seatConfig && seatConfig.length  ? 'Edit Configuration' : 'Add Configuration'}
        </Button>
      </span>
      {modal &&
        <Dialog
          className='project-management-modal'
          open={modal}
          onClose={() => setModal(false)}
          aria-labelledby="scroll-dialog-title"
        >
          <DialogTitle id="scroll-dialog-title">
            {Object.keys(seatConfig).length  && seatConfig && seatConfig.length  ? 'Edit Configuration' : 'Add Configuration'}
          </DialogTitle>
          <DialogContent dividers={true}>
            <form className="aircaft-seat-config-modal">
              {configList?.map((item, sectionIndex) => {
                let seatOptions = pltConstants?.filter(constant => constant.type === 'pax_seat_type');
                let seatType = item.seat_type ? seatOptions.find(seat => seat.value === item.seat_type) : null;
                return (
                  <Paper className="aircraft-section" style={{ marginBottom: '10px', padding: '10px' }} key={sectionIndex}>
                    {configList.length > 1 && sectionIndex !== 0 &&
                      <span className="remove-section">
                        <Tooltip title='Remove Section' placement="bottom-end">
                          <DeleteOutlineIcon style={{ float: 'right', cursor: 'pointer' }} onClick={() => onRemoveConfigSection(item, sectionIndex)} color="secondary" />
                        </Tooltip>
                      </span>
                    }
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12}>
                        <Autocomplete
                          options={pltConstants?.filter(item => item.type === 'pax_seat_type')}
                          getOptionLabel={option => option.label}
                          id="seat_type"
                          value={seatType}
                          onChange={(event, data) => { onFieldChange('seat_type', data ? data.value : '', sectionIndex); onUpdateError('seat_type', sectionIndex) }}
                          renderInput={params =>
                            <TextField
                              required
                              error={sectionError[sectionIndex] && sectionError[sectionIndex].seat_type ? true : false}
                              helperText={sectionError[sectionIndex] && sectionError[sectionIndex].seat_type ? sectionError[sectionIndex].seat_type : ''}
                              {...params}
                              label="Select Class"
                              margin="normal"
                              fullWidth
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                            />
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <TextField
                              required
                              id="starting_row"
                              label="Enter Start Row Number"
                              fullWidth
                              margin="normal"
                              variant='outlined'
                              value={item.starting_row}
                              inputProps={{ maxLength: 2 }}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('starting_row', e.target.value, sectionIndex) : e.preventDefault(); onUpdateError('empty_numner', sectionIndex); }}
                              onFocus={() => { onUpdateError('start_empty_number', sectionIndex) }}
                              error={sectionError[sectionIndex]?.start_empty_number}
                              helperText={sectionError[sectionIndex]?.start_empty_number}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <TextField
                              required
                              id="end_row"
                              label="Enter End Row Number"
                              fullWidth
                              margin="normal"
                              variant='outlined'
                              value={item.end_row}
                              inputProps={{ maxLength: 2 }}
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) => { regexConstants.onlyNumeric.test(e.target.value) ? onFieldChange('end_row', e.target.value, sectionIndex) : e.preventDefault(); onUpdateError('empty_numner', sectionIndex); }}
                              onFocus={() => { onUpdateError('end_empty_number', sectionIndex) }}
                              error={sectionError[sectionIndex]?.end_empty_number}
                              helperText={sectionError[sectionIndex]?.end_empty_number}
                            />
                          </Grid>
                        </Grid>
                        {sectionError[sectionIndex] &&
                          <>
                            {sectionError[sectionIndex].unique_number &&
                              <p className="error-msg">{sectionError[sectionIndex].unique_number}</p>
                            }
                          </>
                        }
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          {item.columns.map((col, colIndex) =>
                            <Grid item xs={12} md={4} key={colIndex}>
                              <p>
                                <span><AddCircleIcon onClick={() => onAddColumnSeat(colIndex, sectionIndex)} color={col.length < 4 ? "primary" : "disabled"} /></span>
                                <span><RemoveCircleIcon onClick={() => onRemoveColumnSeat(colIndex, sectionIndex)} color={col.length > 0 ? "primary" : "disabled"} /></span>
                              </p>
                              <ul className="list-inline seat-number">
                                {col.map((seat, seatIndex) =>
                                  <li className="list-inline-item" key={seatIndex}>
                                    <TextField
                                      style={{ width: '30px', textAlign: 'center' }}
                                      fullWidth
                                      margin="none"
                                      value={seat}
                                      variant='outlined'
                                      inputProps={{ maxLength: 1 }}
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) => { e.target.value === '' || regexConstants.onlyCharacter.test(e.target.value) ? onEditColumnSeatNo(e.target.value.toUpperCase(), seatIndex, colIndex, sectionIndex) : e.preventDefault() }}
                                      onFocus={() => onUpdateError('unique_seat', sectionIndex)}
                                    />
                                  </li>
                                )}
                              </ul>
                            </Grid>
                          )}
                        </Grid>
                        {sectionError[sectionIndex] && sectionError[sectionIndex].unique_seat &&
                          <p className="error-msg">{sectionError[sectionIndex].unique_seat}</p>
                        }
                      </Grid>
                    </Grid>
                  </Paper>
                )
              })}
              <Button className="add-new-section" variant="outlined" color="primary" onClick={onAddConfigSection} size="small">Add Section</Button>
            </form>
          </DialogContent>
          <DialogActions>
            <Button size='small' color="primary" variant="outlined" onClick={() => {setModal(false);getResponseBack()}}>Cancel</Button>
            <Button size='small' disabled={formSubmitLoader} variant="contained" color="primary" onClick={() => setSeatingConfig(configList)}>
              {formSubmitLoader ? <CircularProgress color="#ffffff" size={24} /> : 'Save'}
            </Button>
          </DialogActions>
        </Dialog>
      }
      <DeletePopUp
        modal={deleteModal}
        toggleModalFn={() => { setDeleteModal(false); setDeleteIds({ deleteIndex: '', data: {} }); }}
        title="Delete"
        content={<h4>Are you sure you want to delete?</h4>}
        deleteRecordFn={onremoveSeatConfigRow}
      />
    </>
  );
};

export default withRouter(SetSeatsConfig);
