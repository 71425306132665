import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, TabContent, TabPane, Nav, NavItem, NavLink,Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import classnames from 'classnames';
import { GetInputField, FormHeader, SecondaryTechHeaderRH, SecondaryTechHeaderMid, EditFormBar, DownloadedLogs } from '../../../Elements';
import { MSNSwitcherHeader, ListLoader, ExportFiles, NoRecordFound, LicenceModal } from '../../../../../shared';
import { imgStoragePath } from '../../../../../constants';
import { fetchT005DataAc, saveT005DataAc, uploadT005AttachmentAc, fetchAircraftModelAc, fetchOperatorAc, fetchEngineModelAc, removeComponentAc,removesubComponentIdAc } from '../actionCreators';
import { UPDATE_TECHNICAL_SPECS, ADD_COMPONENT, REMOVE_COMPONENT, ADD_SUB_COMPONENT, ADD_SECTION, ADD_MAINTENANCE_CHECKS,REMOVE_SUB_COMPONENT } from '../actions';
import { downLoadTechFormAc, usageDetailAc } from '../../../../../shared/actionCreators'
import { getFormDetailsAc, updateFormStatusAc } from '../../T001/actionCreators';
import { getLocalStorageInfo } from '../../../../../utils'
import { BUY_LICENCE } from '../../../../../shared/actions';
import { trackActivity } from '../../../../../utils/mixpanel';
import RestrictedAccess from '../../../../../shared_elements/components/RestrictedAccess';
import InvalidUrl from '../../../../../shared_elements/components/InvalidUrl';
import { browserHistory } from 'react-router';

class InspectionFormT005 extends Component {
  constructor(props){
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
      downloadFormLogs: false,
      modal:false
    };
  }

  uploadAttachment = (files, sectionid, key, componentid) =>{
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
      let filesSize = 0
      for(let file of files){
        filesSize += file.size
      }
      if(this.props.storageUsage.used + filesSize >= this.props.storageUsage.available){
        this.props.buyLicence({
          flag: true,
          action: '/licenses/storage',
          content: 'The uploaded file(s) size is not available in storage.'
        })
        return;
      }
    }
    this.props.uploadAttachment(files, sectionid, key, componentid)
  }

  componentDidMount(){
    this.props.fetchT005Data();
    this.props.getFormDetails(1, 'T005');
    this.props.fetchAircraft();
    this.props.fetchOperator();
    this.props.fetchEngineModel();
    if(getLocalStorageInfo().user.permission.records && getLocalStorageInfo().user.permission.records.is_saas){
			this.props.usageDetail()
		}
  }
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleModal=()=> {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleDownloadedLogs = () => {
    this.setState(prevState => ({
      ...prevState,
      downloadFormLogs: !prevState.downloadFormLogs
    }))
  }
  updateForm = (otherInfo, specsType, key, value, sectionIndex, componentIndex=null, subComponentIndex=null, customSubComponentIndex=null) => {
    this.props.updateTechnicalInspection(otherInfo, specsType, key, value, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex);
  }
  readTheAnswer = (answers, key) => {
    let answer = answers.filter(answer => answer.label == key);
    if(answer.length){
      return answer[0].value
    }
  }
  // onBlur = (value, subcomponentsItemIndex, index, componentIndex, subcomponentsIndex) =>{
  //   this.props.updateTechnicalInspection(value, subcomponentsItemIndex, index, componentIndex, subcomponentsIndex);
  // }
  readTheDropDownLabel = (options, answer) => {
    if(answer){
      if(options.filter(item => item.value == answer).length){
        return options.filter(item => item.value == answer)[0].label
      }else{
        return ''
      }
    }else {
      return ''
    }
  }

  updateFormStatus=(data)=>{
    if(data.status==7){
      this.toggleModal();
    }else{
      this.props.updateFormStatus(data);
    }
  }

  saveT005Data = () => {
    this.props.saveT005Data(this.props.technicalInspection)
  }
  render(){
    const { technicalInspectionLoader, technicalInspection, formInfo, formStatusLoader } = this.props;

    let type = formInfo && formInfo.current_form && formInfo.current_form.permissions.can_save ? 'edit': 'view';
    let formViewPermission = formInfo?.list?.length && formInfo?.list?.find(form => form.slug == formInfo?.current_form?.slug)
    let formType = window.location?.pathname?.toUpperCase()?.includes(formInfo?.current_form?.name)

    return(
      <div style={formViewPermission && formType?{}:{backgroundColor:'#fff', marginLeft:"40px"}} className="technical-inspection-cn">
         {formViewPermission && formType? 
        <MSNSwitcherHeader
          SecodaryHeaderRH = {<SecondaryTechHeaderRH projectInfo={formInfo.project}/>}
          SecondaryHeaderMid = {<SecondaryTechHeaderMid projectInfo={formInfo.project} activeAsset={formInfo.asset}/>}
          activeAsset={formInfo.asset}
          projectSlug = {Object.keys(formInfo).length ? formInfo.project.slug: ''}
        />: null}
        {
          formViewPermission && formType ? 
          <FormHeader
          formInfo={formInfo}
          exportReport={ <ExportFiles exportFile={(fileType) => this.props.exportReport('technical-specification-sections','MSN_'+formInfo.asset.msn+'_'+formInfo.current_form.name,fileType)} exportName={'Export Report '} files={[{img: 'pdf_icon.png', label: 'PDF', type: 'pdf'},{img:'docx_exp_icon.png', label: 'Document', type:'docx'}]} />}
          saveButton={<button className="primary-btn" onClick={() => this.saveT005Data()}>Save</button>}
          updateFormStatus={(data) => this.updateFormStatus(data)}
          toggleDownloadedLogs = {this.toggleDownloadedLogs}
          />
        :null}
        {technicalInspectionLoader || formStatusLoader ? null :
          formType?
        formViewPermission ? 
        <div className="form-t005-cn">
          <Nav tabs className="commons-tabs" style={{marginTop:'30px'}}>
            { technicalInspection.map((section, index) =>
              <NavItem key={index}>
                <NavLink
                  className={classnames({ active: this.state.activeTab === index })}
                  onClick={() => { this.toggle(index); trackActivity('Section Tab Clicked', { page_title: 'T005', application_module: 'Technical Specifications',  item_type:'Technical Specifications', section_name:section })}}
                >
                  {section.name}
                </NavLink>
              </NavItem>
            )}
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            { technicalInspection.map((section,sectionIndex) =>
                section.name != 'Maintenance Check updates' ?
                <TabPane key={sectionIndex} tabId={sectionIndex}>
                  <div className="inline-editable-forms" style={{background: '#fff', boxShadow: '0 2px 4px 0 rgba(0,0,0,.15)', padding: '20px'}}>
                    <Row>
                      {section.asset_specs && Object.keys(section.asset_specs).length ?
                        Object.keys(section.asset_specs).map((key, assetIndex) =>{

                          return (
                            <Col md="3" key={assetIndex} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                              {
                                GetInputField(
                                  section.asset_specs[key].field_type,
                                  <label className="for-label">{ section.asset_specs[key].label} </label>,
                                  {
                                    type: type,
                                    value:  this.readTheAnswer(section.answers, key),
                                    error: '',
                                    options: section.asset_specs[key].field_type == 'dropdown' ? section.asset_specs[key].api === true ? this.props[section.asset_specs[key].api_code]: section.asset_specs[key].options:null,
                                    updateForm: (value) => this.updateForm(section.asset_specs[key], 'asset_specs',key, value, sectionIndex,  ),
                                    dropDownValue: section.asset_specs[key].field_type == 'dropdown' ? section.asset_specs[key].api === true ? this.readTheDropDownLabel(this.props[section.asset_specs[key].api_code],  this.readTheAnswer(section.answers, key)) : this.readTheDropDownLabel(section.asset_specs[key].options,  this.readTheAnswer(section.answers, key)):'',
                                    isDisabled: !section.asset_specs[key].editable
                                  }
                                )
                              }
                            </Col>
                          )
                        }

                      ):null}
                      {section.maintenance_specs && Object.keys(section.maintenance_specs).length ?
                        Object.keys(section.maintenance_specs).map((key, mntIndex) =>
                          <Col md="3" key={mntIndex} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                            {
                              GetInputField(
                                section.maintenance_specs[key].field_type,
                                <label className="for-label">{ section.maintenance_specs[key].label} </label>,
                                {
                                  type: type,
                                  value:  this.readTheAnswer(section.answers, key),
                                  error: '',
                                  options: section.maintenance_specs[key].field_type == 'dropdown' ? section.maintenance_specs[key].api === true  ? this.props[section.maintenance_specs[key].api_code] :section.maintenance_specs[key].options:null,
                                  updateForm: (value) => this.updateForm(section.maintenance_specs[key], 'maintenance_specs',key, value, sectionIndex),
                                  dropDownValue: section.maintenance_specs[key].field_type == 'dropdown' ? section.maintenance_specs[key].api === true ? this.readTheDropDownLabel(this.props[section.maintenance_specs[key].api_code], this.readTheAnswer(section.answers, key)): this.readTheDropDownLabel(section.maintenance_specs[key].options, this.readTheAnswer(section.answers, key)) :''
                                }
                              )
                            }
                          </Col>
                      ):null}
                      {section.specs && Object.keys(section.specs).length ?
                        Object.keys(section.specs).map((key, secIndex) =>
                          <Col md="3" key={secIndex} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                            {
                              GetInputField(
                                section.specs[key].field_type,
                                <label className="for-label">{ section.specs[key].label} </label>,
                                {
                                  type: type,
                                  value:  this.readTheAnswer(section.answers, key),
                                  error: '',
                                  options: section.specs[key].field_type == 'dropdown' ? section.specs[key].options:null,
                                  updateForm: (value) => this.updateForm(section.specs[key], 'specs', key, value, sectionIndex),
                                  dropDownValue: section.specs[key].field_type == 'dropdown' ?  this.readTheDropDownLabel(section.specs[key].options, this.readTheAnswer(section.answers, key)):''
                                }
                              )
                            }
                          </Col>
                      ):null}
                    </Row>
                    <div className="">
                      { section.component_questions && section.component_questions.length ?
                        section.component_questions.map((component, componentIndex) =>
                        <div key={componentIndex} className="" style={{background: '#F7F7F7', marginBottom: '20px', padding: '15px'}}>
                          <h4 style={{fontSize: '16px', color: '#691AD3', marginBottom: '20px'}}>
                            {component.name}
                            { component.allow_dynamic ?
                              type=='edit'?
                              <span style={{padding: '0 15px'}} onClick={() => {this.props.addComponent(component, componentIndex, sectionIndex); trackActivity('Component Added', { page_title: 'T005', application_module: 'Technical Specifications',  item_type:'Technical Specifications', component_name:component })}} className="add-component">
                                + Add {component.name}
                              </span> : null
                              : null
                            }
                          </h4>
                          { component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                            <Row>
                              { Object.keys(component.maintenance_specs).map((key, index) =>
                                <Col md="3" key={index} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                  {
                                    GetInputField(
                                      component.maintenance_specs[key].field_type,
                                      <label className="for-label">{ component.maintenance_specs[key].label} </label>,
                                      {
                                        type: type,
                                        value:  this.readTheAnswer(component.answers, key),
                                        error: '',
                                        options: component.maintenance_specs[key].field_type == 'dropdown' ? component.maintenance_specs[key].options:null,
                                        updateForm: (value) => this.updateForm( component.maintenance_specs[key], 'maintenance_specs',key, value, sectionIndex, componentIndex),
                                        dropDownValue: component.maintenance_specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(component.maintenance_specs[key].options, this.readTheAnswer(component.answers, key)) :''
                                      }
                                    )
                                  }
                                </Col>
                              )}
                            </Row>:null
                          }
                          { component.specs && Object.keys(component.specs).length ?
                            <Row>
                              { Object.keys(component.specs).map((key, index) =>
                                <Col md="3" key={index} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                  {
                                    component.specs[key].field_type == 'attach' ?
                                    <div className="">
                                      <label className="for-label">{ component.specs[key].label} </label>
                                      {
                                        type == 'edit'?
                                        <span style={{background: '#e1e1e1', padding: '10px 50px 10px 10px',  fontSize: '14px', display: 'inline-block', position: 'relative', borderRadius: '3px'}}>
                                          Choose File
                                          <input onChange={(evt) => this.uploadAttachment(evt.target.files, section.id, key, component.id) } style={{position: 'absolute', cursor: 'pointer', top: '0', left: '0', width: '100%', height: '100%', opacity: 0, zIndex: 1}} type="file" />
                                        </span>:null
                                      }
                                      { this.readTheAnswer(component.answers, key) ?
                                        <a href={this.readTheAnswer(component.answers, key)} target="_blank">
                                          <img src={this.readTheAnswer(component.answers, key)} width="200" alt="" />
                                        </a>
                                        :null
                                      }

                                    </div>:
                                    GetInputField(
                                      component.specs[key].field_type,
                                      <label className="for-label">{ component.specs[key].label} </label>,
                                      {
                                        type: type,
                                        value:  this.readTheAnswer(component.answers, key),
                                        error: '',
                                        options: component.specs[key].field_type == 'dropdown' ? component.specs[key].options:null,
                                        updateForm: (value) => this.updateForm(component.specs[key], 'specs',key, value, sectionIndex, componentIndex),
                                        dropDownValue: component.specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(component.specs[key].options, this.readTheAnswer(component.answers, key)) :''
                                      }
                                    )
                                  }
                                </Col>
                              )}
                            </Row>:null
                          }
                          { component.custom_components && component.custom_components.length ?
                            component.custom_components.map((customComponents, subComponentIndex) =>
                            <div className="" key={subComponentIndex} style={{borderTop: '1px solid #d7d7d7', paddingTop: '20px'}}>
                             { type=='edit'?
                              <span onClick={() =>  customComponents.id ?this.props.removeComponentId(sectionIndex, componentIndex, subComponentIndex,customComponents.id,section.id): this.props.removeComponent(sectionIndex, componentIndex, subComponentIndex)} style={{float: 'right', cursor: 'pointer'}}> <img width="15" src={imgStoragePath+"delete_red.png"} /></span>:null
                              }
                             <Row>
                                {
                                  customComponents.maintenance_specs && Object.keys(customComponents.maintenance_specs).length ?
                                  Object.keys(customComponents.maintenance_specs).map((key, index) =>
                                  <Col md="3" key={index} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                    {
                                      GetInputField(
                                        customComponents.maintenance_specs[key].field_type,
                                        <label className="for-label">{ customComponents.maintenance_specs[key].label} </label>,
                                        {
                                          type: type,
                                          value:  this.readTheAnswer(customComponents.answers, key),
                                          error: '',
                                          options: customComponents.maintenance_specs[key].field_type == 'dropdown' ? customComponents.maintenance_specs[key].options:null,
                                          updateForm: (value) => this.updateForm(customComponents.maintenance_specs[key], 'custom_components',key, value, sectionIndex, componentIndex, subComponentIndex),
                                          dropDownValue: customComponents.maintenance_specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(customComponents.maintenance_specs[key].options, this.readTheAnswer(customComponents.answers, key)) :''
                                        }
                                      )
                                    }
                                  </Col>
                                ):null}
                                { customComponents.specs && Object.keys(customComponents.specs).length ?
                                  Object.keys(customComponents.specs).map((key, index) =>
                                    <Col md="3" key={index} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                      {
                                        GetInputField(
                                          customComponents.specs[key].field_type,
                                          <label className="for-label">{ customComponents.specs[key].label} </label>,
                                          {
                                            type: type,
                                            value:  this.readTheAnswer(customComponents.answers, key),
                                            error: '',
                                            options: customComponents.specs[key].field_type == 'dropdown' ? customComponents.specs[key].options:null,
                                            updateForm: (value) => this.updateForm(customComponents.specs[key], 'specs',key, value, sectionIndex, componentIndex, subComponentIndex),
                                            dropDownValue: customComponents.specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(customComponents.specs[key].options, this.readTheAnswer(customComponents.answers, key)) :''
                                          }
                                        )
                                      }
                                    </Col>
                                ):null}
                              </Row>
                            </div>
                          ):null}
                          { component.sub_components && component.sub_components.length ?
                            component.sub_components.map((subComponent, subComponentIndex) =>
                            <div key={subComponentIndex} className="" style={{background: '#edecef', padding: '15px', marginBottom: '20px'}}>
                              <h5 style={{fontSize: '14px', color: '#691ad3', paddingBottom: '10px', marginBottom: '20px'}}>
                                {subComponent.name}
                                { subComponent.allow_dynamic ?
                                type == 'edit' ?
                                  <span style={{padding: '0 15px'}} onClick={() => {this.props.addComponent(subComponent, subComponentIndex, componentIndex); trackActivity('Component Added', { page_title: 'T005', application_module: 'Technical Specifications',  item_type:'Technical Specifications', component_name:subComponent })}} className="add-component">
                                    + Add {subComponent.name}
                                  </span> : null
                                  : null
                                }
                              </h5>
                              <Row>
                                { subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                  Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                    <Col md="3" key={index} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                      {
                                        GetInputField(
                                          subComponent.maintenance_specs[key].field_type,
                                          <label className="for-label">{ subComponent.maintenance_specs[key].label} </label>,
                                          {
                                            type: type,
                                            value:  this.readTheAnswer(subComponent.answers, key),
                                            error: '',
                                            options: subComponent.maintenance_specs[key].field_type == 'dropdown' ? subComponent.maintenance_specs[key].options:null,
                                            updateForm: (value) => this.updateForm(subComponent.maintenance_specs[key], 'maintenance_specs',key, value, sectionIndex, componentIndex, subComponentIndex),
                                            dropDownValue: subComponent.maintenance_specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(subComponent.maintenance_specs[key].options, this.readTheAnswer(subComponent.answers, key)) :''
                                          }
                                        )
                                      }
                                    </Col>
                                ):null}
                                { subComponent.specs && Object.keys(subComponent.specs).length ?
                                  Object.keys(subComponent.specs).map((key, index) =>
                                    <Col md="3" key={index} style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                      {
                                        GetInputField(
                                          subComponent.specs[key].field_type,
                                          <label className="for-label">{ subComponent.specs[key].label} </label>,
                                          {
                                            type: type,
                                            value:  this.readTheAnswer(subComponent.answers, key),
                                            error: '',
                                            options: subComponent.specs[key].field_type == 'dropdown' ? subComponent.specs[key].options:null,
                                            updateForm: (value) => this.updateForm(subComponent.specs[key], 'specs',key, value, sectionIndex, componentIndex, subComponentIndex),
                                            dropDownValue:subComponent.specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(subComponent.specs[key].options, this.readTheAnswer(subComponent.answers, key)) :''
                                          }
                                        )
                                      }
                                    </Col>
                                ):null}
                              </Row>
                            </div>
                          ):null}
                        </div>
                      ):null}
                    </div>
                    <div className="">
                      { section.sections && section.sections.length ?
                        section.sections.map((component, componentIndex) =>
                        <div key={componentIndex} className="" style={{background: '#F7F7F7', marginBottom: '20px', padding: '15px', borderTop: '1px solid #'}}>
                          <h4 style={{fontSize: '16px', color: '#691AD3', marginBottom: '20px'}}>{component.name}</h4>
                          { component.maintenance_specs && Object.keys(component.maintenance_specs).length ?
                            <Row>
                              { Object.keys(component.maintenance_specs).map((key, index) =>
                                <Col key={index} md="3" style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                  {
                                    GetInputField(
                                      component.maintenance_specs[key].field_type,
                                      <label className="for-label">
                                        { component.maintenance_specs[key].label}
                                      </label>,
                                      {
                                        type: type,
                                        value:  this.readTheAnswer(component.answers, key),
                                        error: '',
                                        options: component.maintenance_specs[key].field_type == 'dropdown' ? component.maintenance_specs[key].options:null,
                                        updateForm: (value) => this.updateForm(component.maintenance_specs[key], 'sections',key, value, sectionIndex, componentIndex),
                                        dropDownValue: component.maintenance_specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(component.maintenance_specs[key].options, this.readTheAnswer(component.answers, key)) :''
                                      }
                                    )
                                  }
                                </Col>
                              )}
                            </Row>:null
                          }
                          { component.specs && Object.keys(component.specs).length ?
                            <Row>
                              { Object.keys(component.specs).map((key, index) =>
                                key != 'is_fitted' ?
                                  <Col key={index} md="3" style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                    {
                                      GetInputField(
                                        component.specs[key].field_type,
                                        <label className="for-label">
                                          { component.specs[key].label}
                                          {
                                            component.specs[key].is_fitted == "true" ?
                                            <label onClick={() => type=='edit' ? this.updateForm(component.specs[key], 'sections','is_fitted',this.readTheAnswer(component.answers, 'is_fitted')&&this.readTheAnswer(component.answers, 'is_fitted').toLowerCase() == "true" ? "false":"true", sectionIndex, componentIndex):''}>:
                                              <input style={ type == 'edit' ? {display: 'inline-block',marginLeft:'5px'}:{display: 'none'} } checked={this.readTheAnswer(component.answers, 'is_fitted')&&this.readTheAnswer(component.answers, 'is_fitted').toLowerCase() == "true"  ? true:false} type="checkbox" /> <span style={{color: '#000'}}> Is Fitted</span></label>:null
                                          }
                                        </label>,
                                        {
                                          type: type,
                                          value:  this.readTheAnswer(component.answers, key),
                                          error: '',
                                          options: component.specs[key].field_type == 'dropdown' ? component.specs[key].options:null,
                                          updateForm: (value) => this.updateForm(component.specs[key], 'sections',key, value, sectionIndex, componentIndex),
                                          isDisabled: component.specs[key].is_fitted == "true" &&  (component.answers.filter(answer => answer.label == 'is_fitted').length == 0 || component.answers.filter(answer => answer.label == 'is_fitted')[0].value == false) ? true:false,
                                        }
                                      )
                                    }
                                  </Col>:null
                              )}
                            </Row>:null
                          }
                          { component.sub_components && component.sub_components.length ?
                            component.sub_components.map((subComponent, subComponentIndex) =>
                            <div key={subComponentIndex} className="">
                              <h5 style={{fontSize: '16px', borderBottom: '1px solid #d7d7d7', paddingBottom: '10px', marginBottom: '20px'}}>
                                {subComponent.name}
                                { subComponent.allow_dynamic ?
                                type == 'edit' ?
                                  <span onClick={() => {this.props.addComponent(subComponent, subComponentIndex, componentIndex); trackActivity('Component Added', { page_title: 'T005', application_module: 'Technical Specifications',  item_type:'Technical Specifications', component_name:subComponent })}} className="add-component">
                                    + Add {subComponent.name}
                                  </span> : null
                                  : null
                                }
                              </h5>
                              <Row>
                                { subComponent.maintenance_specs && Object.keys(subComponent.maintenance_specs).length ?
                                  Object.keys(subComponent.maintenance_specs).map((key, index) =>
                                    <Col key={index} md="3" style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                      {
                                        GetInputField(
                                          subComponent.maintenance_specs[key].field_type,
                                          <label className="for-label">{ subComponent.maintenance_specs[key].label} </label>,
                                          {
                                            type: type,
                                            value:  this.readTheAnswer(subComponent.answers, key),
                                            error: '',
                                            options: subComponent.maintenance_specs[key].field_type == 'dropdown' ? subComponent.maintenance_specs[key].options:null,
                                            updateForm: (value) => this.updateForm(subComponent.maintenance_specs[key], 'maintenance_specs',key, value, sectionIndex, componentIndex, subComponentIndex),
                                            dropDownValue: subComponent.maintenance_specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(subComponent.maintenance_specs[key].options, this.readTheAnswer(subComponent.answers, key)) :''
                                          }
                                        )
                                      }
                                    </Col>
                                ):null}
                                { subComponent.specs && Object.keys(subComponent.specs).length ?
                                  Object.keys(subComponent.specs).map((key, index) =>
                                    <Col key={index} md="3" style={{marginBottom: '30px', paddingLeft: '20px', paddingRight: '20px'}}>
                                      {
                                        GetInputField(
                                          subComponent.specs[key].field_type,
                                          <label className="for-label">{ subComponent.specs[key].label} </label>,
                                          {
                                            type: type,
                                            value:  this.readTheAnswer(subComponent.answers, key),
                                            error: '',
                                            options: subComponent.specs[key].field_type == 'dropdown' ? subComponent.specs[key].options:null,
                                            updateForm: (value) => this.updateForm(subComponent.specs[key], 'specs',key, value, sectionIndex, componentIndex, subComponentIndex),
                                            dropDownValue:subComponent.specs[key].field_type == 'dropdown' ? this.readTheDropDownLabel(subComponent.specs[key].options, this.readTheAnswer(subComponent.answers, key)) :''
                                          }
                                        )
                                      }
                                    </Col>
                                ):null}
                              </Row>

                            </div>
                          ):null}
                          { component.allow_dynamic ?
                            type == 'edit' ?
                              <span onClick={() => {this.props.addSection(component, componentIndex); trackActivity('Component Added', { page_title: 'T005', application_module: 'Technical Specifications',  item_type:'Technical Specifications', component_name:component })}} className="add-component">
                                + Add {component.name}
                              </span>:null
                            :null
                          }
                        </div>
                      ):null}
                    </div>
                  </div>
                </TabPane>
                :
                <TabPane key={sectionIndex} tabId={sectionIndex}>
                  <div className="inline-editable-forms" style={{background: '#fff', boxShadow: '0 2px 4px 0 rgba(0,0,0,.15)', padding: '20px'}}>
                    { section.component_questions.map((component, componentIndex) =>
                      <div className="">
                        <h4 style={{fontSize: '16px', color: '#691ad3'}}>
                          {component.name}
                          {type=='edit'?
                          <span style={{padding: '0 15px'}} onClick={() => {this.props.addMaintenanceComponent(component, sectionIndex, componentIndex); trackActivity('Component Added', { page_title: 'T005', application_module: 'Technical Specifications',  item_type:'Technical Specifications', component_name:component })}} className="add-component">
                            + Add {component.name}
                          </span>:null
                          }
                        </h4>
                        <table style={{width: '100%', marginBottom: '20px'}}>
                          <thead>
                            <tr>
                              {component.sub_components.map((subComponent, subComponentIndex) => <th style={{padding: '10px', fontSize: '14px'}}>{subComponent.name}</th>)}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              { component.sub_components.map((subComponent, subComponentIndex) =>
                                <td>
                                  <table style={{width: '100%'}}>
                                    <tr>
                                      { Object.keys(subComponent.specs).map((key, index) =>
                                        <td style={{padding: '10px'}}>
                                          {
                                            GetInputField(
                                              subComponent.specs[key].field_type,
                                              <label className="for-label">{ subComponent.specs[key].label} </label>,
                                              {
                                                type: type=='edit' ? subComponent.specs[key].editable === false ? 'view': 'edit':'view',
                                                value:  this.readTheAnswer(subComponent.answers, key),
                                                error: '',
                                                options: subComponent.specs[key].field_type == 'dropdown' ? subComponent.specs[key].options:null,
                                                updateForm: (value) => this.updateForm(subComponent.specs[key], 'specs', key, value, sectionIndex, componentIndex, subComponentIndex)
                                              }
                                            )
                                          }
                                        </td>
                                      )}
                                    </tr>
                                  </table>
                                  { subComponent.custom_components && subComponent.custom_components.length ?
                                   <table style={{width: '100%',padding:'0px'}}>
                                      { subComponent.custom_components.map((customSubComponent, customSubComponentIndex) =>
                                        <tr>
                                          {Object.keys(customSubComponent.specs).map((key, index) =>
                                            <td style={{padding: '10px'}}>
                                              {
                                                GetInputField(
                                                  customSubComponent.specs[key].field_type,
                                                  <label className="for-label">{ customSubComponent.specs[key].label} </label>,
                                                  {
                                                    type: type=='edit' ?customSubComponent.specs[key].editable === false ? 'view': 'edit':'view',
                                                    value:  this.readTheAnswer(customSubComponent.answers, key),
                                                    error: '',
                                                    options: customSubComponent.specs[key].field_type == 'dropdown' ? customSubComponent.specs[key].options:null,
                                                    updateForm: (value) => this.updateForm(customSubComponent.specs[key], 'custom_subComponent', key, value, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex)
                                                  }
                                                )
                                              }
                                            </td>
                                          )}
                                          {(subComponentIndex+1)==component.sub_components.length?
                                           type=='edit'?
                                          <span onClick={() => {customSubComponent.id ?this.props.removesubComponentId(sectionIndex, componentIndex, subComponentIndex,customSubComponentIndex,component,section.id):this.props.removesubComponent(sectionIndex, componentIndex, subComponentIndex,customSubComponentIndex); customSubComponent.id ? trackActivity('Component Removed', { page_title: 'T005', application_module: 'Inspection',  item_type:'Technical Specifications', component_name:component }) : trackActivity('Sub Component Removed', { page_title: 'T005', application_module: 'Technical Specification',  item_type:'Technical Specifications' })} }style={{float: 'right', cursor: 'pointer'}}> <img width="15" src={imgStoragePath+"delete_red.png"} /></span>:null
                                           :null
                                          }
                                        </tr>
                                      )}
                                    </table>
                                   :null
                                  }
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </TabPane>
            )}
          </TabContent>
        </div> 
      :  <RestrictedAccess />  
      :
      <InvalidUrl getResponseBack={()=>browserHistory.push(`/technical/project/view/${formInfo?.project?.slug}`) } />
      }
        { technicalInspectionLoader || formStatusLoader ? <ListLoader drawer="primary-nav" />:null}
        <EditFormBar
          titleContent= { <div><h3 style={{ textTransform: 'capitalize'}}>Downloaded Logs</h3></div>}
          toggleEditSideBar={this.toggleDownloadedLogs}
          isOpen={this.state.downloadFormLogs}
          style={this.state.downloadFormLogs ? {width: '400px'} : {width: '0px'}}
          >
          {this.state.downloadFormLogs ?
            <DownloadedLogs logs={formInfo.current_form.download_report} />
            :null
          }
        </EditFormBar>
        {
          this.props.buyLicenceModal.flag ?
          <LicenceModal
            planId="2"
            toggleLicence={() => this.props.buyLicence({
              flag: false,
              action: '',
              content: ''
            })}
            licenceModal={this.props.buyLicenceModal}
            />
          :null
        }

          <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className}>
            <ModalHeader toggle={this.toggleModal}>How do you want to publish ?</ModalHeader>
            <ModalBody>
              Please select Publish &amp; Apply to Asset - If you want to publish the form and apply the data points to asset technical specifications.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={()=>{this.toggleModal();this.props.updateFormStatus({status:7,update_on_tech_specs:true})}}>Publish &amp; Apply to asset</Button>
              <Button color="primary" onClick={()=>{this.toggleModal();this.props.updateFormStatus({status:7})}}>Publish Only</Button>
              <Button color="secondary" style={{background:'#dc3545'}} onClick={this.toggleModal}>Cancel</Button>
            </ModalFooter>
          </Modal>

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  technicalInspection: state.FormT005Reducer.technicalInspection,
  technicalInspectionLoader: state.FormT005Reducer.technicalInspectionLoader,
  formInfo: state.FormT001Reducer.formInfo,
  aircraft_model: state.FormT005Reducer.aircraft_model,
  lessee_list: state.FormT005Reducer.lessee_list,
  engine_model: state.FormT005Reducer.engine_model,
  formStatusLoader:state.FormT001Reducer.formStatusLoader,
  buyLicenceModal: state.sharedReducers.buyLicenceModal,
  storageUsage: state.sharedReducers.storageUsage,
});
const mapDispatchToProps = (dispatch,ownProps) =>{
  return{
    fetchT005Data: () => dispatch(fetchT005DataAc(ownProps)),
    getFormDetails: (type, application) => dispatch(getFormDetailsAc(ownProps, type, application)),
    updateFormStatus: (data) => dispatch(updateFormStatusAc(ownProps, data)),
    exportReport:(url,name,fileType) => dispatch(downLoadTechFormAc(url,ownProps.params.workOrderSlug,fileType,name)),
    updateTechnicalInspection: (otherInfo, specsType, key, value, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex) => dispatch({
      type: UPDATE_TECHNICAL_SPECS,
      payload: {otherInfo, specsType, key, value, sectionIndex, componentIndex, subComponentIndex, customSubComponentIndex }
    }),
    addComponent: (component, componentIndex, sectionIndex) => dispatch({
      type: ADD_COMPONENT,
      payload: { component, componentIndex, sectionIndex }
    }),
    removeComponentId:(sectionIndex, componentIndex, subComponentIndex,id,sectionId)=>dispatch(removeComponentAc(ownProps,sectionIndex, componentIndex, subComponentIndex,id,sectionId)),
    removeComponent:(sectionIndex, componentIndex, subComponentIndex) => dispatch({type: REMOVE_COMPONENT, payload: {sectionIndex, componentIndex, subComponentIndex} }),
    removesubComponentId:(sectionIndex, componentIndex, subComponentIndex,customSubComponentIndex,component,sectionId)=> dispatch(removesubComponentIdAc(ownProps,sectionIndex, componentIndex, subComponentIndex,customSubComponentIndex,component,sectionId)),
    removesubComponent:(sectionIndex, componentIndex, subComponentIndex,customSubComponentIndex)=> dispatch({type: REMOVE_SUB_COMPONENT, payload: {sectionIndex, componentIndex, subComponentIndex,customSubComponentIndex} }),
    addSection: (component, index, sectionIndex) => dispatch({ type: ADD_SECTION, payload: { index, component, sectionIndex }}),
    addSubComponent: (component, subIndex, index, sectionIndex) => dispatch({
      type: ADD_SUB_COMPONENT,
      payload: {
        index,
        component,
        sectionIndex,
        subIndex
      }
    }),
    addMaintenanceComponent: (component, sectionIndex, componentIndex) => dispatch({
      type: ADD_MAINTENANCE_CHECKS,
      payload: {component, sectionIndex, componentIndex}
    }),
    uploadAttachment: (file, sectionId, label, componet_id=null, sub_component_id=null) => dispatch(uploadT005AttachmentAc(ownProps, file, sectionId, label, componet_id, sub_component_id)),
    saveT005Data: (data) => dispatch(saveT005DataAc(ownProps, data)),
    fetchAircraft: () => dispatch(fetchAircraftModelAc()),
    fetchOperator: () => dispatch(fetchOperatorAc()),
    fetchEngineModel: () => dispatch(fetchEngineModelAc()),
    usageDetail: () => dispatch(usageDetailAc()),
    buyLicence: (modalObj) => dispatch({
      type: BUY_LICENCE,
      payload: modalObj
    })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InspectionFormT005);
