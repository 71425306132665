import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { Paper, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button, Tooltip,Chip } from '@material-ui/core';
import { ExportManu, DeletePopUp, PageLoader } from '../../../shared_elements';
import { DocumentAnswer, SortItems, AddNewContent, EditContentItem, ImportTemplate, ImportSections,PublishFileStatus } from '../components';
import { FormSkeletonLoader, FormWrapper } from '../../Elements';
import { checkApiStatus, downloadFileType, getLocalStorageInfo, onCheckFormPermission } from '../../../utils_v2';
import { trackActivity } from '../../../utils/mixpanel';
import { globalGetService, globalPutService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices';
import { globalStaticExportService } from '../../../globalServices';
import { castleAirInstance } from '../../../shared_elements/components';
const Workbook = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [checklistLoading, setCheckListLoading] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [formLoader, setFormLoader] = useState(true);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [currentFormDetail, setCurrentFormDetail] = useState(null);
    const [form11Data, setForm11Data] = useState(null);
    const [documentCheckList, setDocumentCheckList] = useState([]);
    const [error, setError] = useState({});
    const [deleteItem, setDeleteItem] = useState({modal:false, itemType:'',  sectionId:null, subSectionId:null});
    const [editItem, setEditItem] = useState({modal:false, itemType:'', data:null,  sectionId:null, subSectionId:null});
    const [importSections, setImportSections] = useState({modal:false});
    const [publishFileStatusModel, setPublishFileStatusModel] = useState(false);
    useEffect(() => {
        getDocumentCheckList({},'skeletonLoader');
        getCurrentFormDetail('formLoader');
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'T013 Form'
          });
    },[]);
    const getCurrentFormDetail = (loaderType) => {
        if(loaderType){setFormLoader(true)}
        globalGetService(`technical/${params.type === 'engine' ? 'engine-workorder' :'workorder'}/${params.workOrderSlug}/forms/`,{form:params.formSlug,fields:'id,name,project_number,start_date,expected_end_date,slug'})
        .then(response => {
            if(checkApiStatus(response)){
                let form11Slug= response.data.data?.list?.find(item => item?.name =='T011')
                globalGetService(`technical/workorder/${params.workOrderSlug}/forms/`,{form:form11Slug?.slug})
                .then(res => {
                    if(loaderType){setFormLoader(false)}
                    if(checkApiStatus(res)){
                        setForm11Data(res.data.data)
                    }
                })
                setCurrentFormDetail(response.data.data)
            }
            else{
                if(loaderType){setFormLoader(false)}
            }
        })
    }
    const onChangeFormStatus = (data) => {
        if(data.status === 7 ){
            setPublishFileStatusModel(true);
        }else{
            onChangeFormStatusApi(data)
        }
    }
    const onChangeFormStatusApi = (data) => {
        setLoading(true)
        globalPostService(`technical/workorder/${params.workOrderSlug}/form/${params.formSlug}/status-update/?asset_type=${params.type}`,data)
        .then(response => {
            setLoading(false)
            if(checkApiStatus(response)){
                getCurrentFormDetail('formLoader');
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Updated',
                    page_title: 'T013 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    success_msg: response.data.message
                });
            }else{
                enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                trackActivity('Project Management', {
                    event_type: 'Form Status Update Failed',
                    page_title: 'T013 Form',
                    form_slug: params.formSlug ? params.formSlug : '',
                    workOrder_slug: params.workOrderSlug ? params.workOrderSlug : '',
                    status: data.status ? data.status: '',
                    request_body: data ? data : '',
                    error_source: 'Backend',
                    error_msg: response.data.message
                });
            }
        });
    }
    const getDocumentCheckList = (query={}, loaderType) => {
        if(loaderType === 'skeletonLoader'){
            setSkeletonLoader(true)
        }else if (loaderType === 'pageLoader') {
            setLoading(true)
        }
        globalGetService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/`,{asset_type:params.type})
        .then(response => {
            if(loaderType === 'pageLoader' || loaderType === 'defaultLoader' ) {
                setCheckListLoading(true);
            }
            if(loaderType === 'skeletonLoader'){
                setSkeletonLoader(false)
            }else if(loaderType === 'pageLoader'){
                setLoading(false)
            }
//            else{
////                setLoading(false)
//            }
            if(checkApiStatus(response)){
                setDocumentCheckList(response.data.data);
                setCheckListLoading(false);
            }
        })
    }
    const exportFormReport = (extension) => {
        enqueueSnackbar('T013 Report Downloading ...', { variant: 'info',anchorOrigin:{horizontal:'right', vertical:'bottom'}})
        globalStaticExportService(`technical/v2/workorder/${params.workOrderSlug}/workbook/export/?asset_type=${params.type}`, {download:extension})
        .then(response => {
            downloadFileType(response, (`T013_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}.`), extension);
            closeSnackbar();
            trackActivity('Project Management', {
                event_type: 'File Exported',
                page_title: 'T013 Form',
                project_slug: params.project_slug ? params.project_slug : '',
                file_name: `T013_${getLocalStorageInfo().defaultLessor.name}_${moment().format('YYYY-MM-DD')}`,
                file_extension: extension,
                success_msg: 'T013 Report Downloaded'
            })
        });
    }
    const onDeleteItem = () => {
        setLoading(true);
        if(deleteItem.itemType ==='section'){
            globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/${deleteItem.sectionId}/?asset_type=${params.type}`)
            .then(response => {
                if(checkApiStatus(response)){
                    getCurrentFormDetail()
                    getDocumentCheckList({}, 'pageLoader')
                    setDeleteItem({modal:false, itemType:'', sectionId:null, subSectionId:null});
                    enqueueSnackbar(response.data.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                }else{
                    setLoading(false);
                    enqueueSnackbar(response?.data?.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                }
            })
        } else{
            globalDeleteService(`technical/v2/workorder/${params.workOrderSlug}/workbook/sections/${deleteItem.sectionId}/sub-section/${deleteItem?.subSectionId}/?asset_type=${params.type}`)
            .then(response => {
                if(checkApiStatus(response)){
                    getDocumentCheckList({}, 'pageLoader')
                    getCurrentFormDetail()
                    setDeleteItem({modal:false, itemType:'', sectionId:null, subSectionId:null});
                    enqueueSnackbar(response.data?.message, {variant: 'success', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                }else{
                    setLoading(false);
                    enqueueSnackbar(response?.data?.message, {variant: 'error', anchorOrigin:{vertical: 'top',horizontal: 'right'}});
                }
            })
        }
    }
    
    const onEditItemType = (data) => {
        getDocumentCheckList()
        // if(editItem.itemType === 'section'){
        //     setDocumentCheckList(documentCheckList.map(section => section.id !== data.id ? section : {...section, ...data}));
        // }else{
        //     setDocumentCheckList(documentCheckList.map(section => section.id !== editItem.sectionId ? section : {...section, sub_sections: section.sub_sections.map(subSection => subSection.id !== data.id ? subSection : {...subSection, ...data})}))
        // }
        setEditItem({modal:false, itemType:'', data:null,  sectionId:null, subSectionId:null});
    }

    return(
        <Fragment>
            <section className='project-forms-section'>
                { currentFormDetail && Object.keys(currentFormDetail).length ?
                    <FormWrapper 
                        hideAction={currentFormDetail?.current_form.progress === 0 ? true:false}
                        formInfo={currentFormDetail} 
                        exportReportBtn={<ExportManu disabled={documentCheckList?.length ? false: true} exportReportFn={(file) => exportFormReport(file.extension)} title="Export Report" files={castleAirInstance? [{title:'Excel', extension: 'xls', key:''}]: [{title:'PDF', extension: 'pdf', key:''},{title:'Doc', extension: 'docx', key:''},{title:'Excel', extension: 'xls', key:''}]} />}
                        updateFormStatusFn={(data) => onChangeFormStatus(data)}
                    />:formLoader ? <FormSkeletonLoader />:null
                }
                <div className='documents-checklist'>
                    { onCheckFormPermission(currentFormDetail) ?
                        <div className='checklist-head'>
                            <Grid container spacing={1}>
                                <Grid item md={6}>
                                    <ul className='list-inline flex-centered left-cta'>
                                        <li className='list-inline-item'>
                                            <Button startIcon={<AddIcon color='primary' fontSize='small' />} onClick={() =>setImportSections({modal:true, slug:params?.workOrderSlug})} color='primary' variant='outlined' size='small'>Import Section</Button>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item md={6}>
                                    <ul className='list-inline flex-centered right-cta'>
                                        { documentCheckList && documentCheckList.length > 1 ?
                                            <li className='list-inline-item'>
                                                <SortItems toolTipContent='Sort Sections' itemType='section' documentCheckList={documentCheckList} getResponseBack={()=> getDocumentCheckList({},'pageLoader')} />
                                            </li>:null
                                        }
                                        <li className='list-inline-item'>
                                            <AddNewContent documentCheckList={documentCheckList} addType='section' getResponseBack={() => {getCurrentFormDetail();getDocumentCheckList({}, 'pageLoader')}} />
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </div>:null
                    }
                    { skeletonLoader ? <Skeleton variant="rect" width={'100%'} height={480} />:
                        <Fragment>
                            {documentCheckList.map((section, index) =>
                                <Paper className="section-list" key={index}>
                                    <ExpansionPanel key={index}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id={`panel${index}-header`}
                                            key={index}
                                        >
                                            <div className='section-head'>
                                                <div style={{width:'800px'}}>
                                                <h2>{section.name}</h2>
                                                </div>
                                                {onCheckFormPermission(currentFormDetail) ?
                                                    <ul style={{ float: 'right' }} className='list-inline flex-centered'>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Edit Section' arrow>
                                                                <EditIcon onClick={() => setEditItem({ modal: true, itemType: 'section', data: { id: section.id, name: section.name, order: section.order, sub_section_series: section.sub_section_series }, sectionId: section.id })} color='primary' fontSize='small' />
                                                            </Tooltip>
                                                        </li>
                                                        <li className='list-inline-item'>
                                                            <Tooltip title='Delete Section' arrow>
                                                                <DeleteOutlineIcon onClick={(e) => { setDeleteItem({ modal: true, itemType: 'section', sectionId: section.id }) }} color='secondary' fontSize='small' />
                                                            </Tooltip>
                                                        </li>
                                                        {section.sub_sections.length > 1 ?
                                                            <li className='list-inline-item'>
                                                                <SortItems toolTipContent='Sort Sub-Sections' itemType='sub-section' sectionId={section.id} documentCheckList={documentCheckList} getResponseBack={() => getDocumentCheckList({}, 'pageLoader')} />
                                                            </li> : null
                                                        }
                                                        <li className='list-inline-item'>
                                                            <AddNewContent documentCheckList={documentCheckList} sectionId={section.id} addType={'sub-section'} getResponseBack={() => {getCurrentFormDetail();getDocumentCheckList({}, 'pageLoader')}} />
                                                        </li>
                                                    </ul> : null
                                                }
                                            </div>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails style={{padding:'0px 7px 16px'}}>
                                            <div className='sub-section-list' style={{width:'100%',padding:'0px 10px 10px'}}>
                                                {section.sub_sections.length === 0 ? <div className='no-data'>No Sub Section Found </div> :
                                                    <Paper>
                                                        {section.sub_sections.map((subSections, subIndex) =>
                                                            <ExpansionPanel key={index}>
                                                                <ExpansionPanelSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id={`section${subIndex}`}
                                                                    key={subIndex}
                                                                >
                                                                    <div className='sub-section-head'>
                                                                        <h4 >{subIndex + 1}. {subSections.name}</h4>
                                                                        <ul className='list-inline'>
                                                                            <li className='list-inline-item'>
                                                                                <Chip label={`Received: ${subSections?.checklists?.length ? subSections.checklists.filter(item => item.status === 1).length : 0}`} size="small" style={{ background: "#277831", color: "#ffffff", width: '110px'}} />
                                                                                <Chip label={`Pending: ${subSections?.checklists?.length ? subSections.checklists.filter(item => item.status === 2).length : 0}`} size="small" style={{ margin: '5px', background: '#8f5050', color: "#ffffff", width: '110px' }} />
                                                                                <Chip label={`N/A: ${subSections?.checklists?.length ? subSections.checklists.filter(item => item.status === 3).length : 0}`} style={{ background: "#ad7900", color: "#ffffff", width: '110px' }} size="small" />
                                                                                <Chip label={`Total Checklists: ${subSections?.checklists?.length ? subSections.checklists.length : 0}`} style={{ marginLeft: '5px', background: '#3f51b5', color: "#ffffff", width: '150px' }} size="small" />
                                                                            </li>
                                                                            {onCheckFormPermission(currentFormDetail) ?
                                                                                <Fragment>
                                                                                    <li className='list-inline-item' style={{ verticalAlign: 'middle' }}>
                                                                                        <Tooltip title='Edit Sub-Section'>
                                                                                            <EditIcon onClick={(e) => { e.preventDefault(); e.stopPropagation(); setEditItem({ modal: true, itemType: 'sub-section', data: { id: subSections.id, name: subSections.name, order: subSections.order }, sectionId: section.id }) }} color='primary' fontSize='small' />
                                                                                        </Tooltip>
                                                                                    </li>
                                                                                    <li className='list-inline-item' style={{ verticalAlign: 'middle' }}>
                                                                                        <Tooltip title='Delete Sub-Section' arrow>
                                                                                            <DeleteOutlineIcon onClick={(e) => { e.preventDefault(); e.stopPropagation(); setDeleteItem({ modal: true, itemType: 'sub-section', sectionId: section.id, subSectionId: subSections.id }) }} color='secondary' fontSize='small' />
                                                                                        </Tooltip>
                                                                                    </li>
                                                                                </Fragment> : null
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails>
                                                                    {checklistLoading ? <div>Refreshing the Data</div> :
                                                                        <DocumentAnswer
                                                                            form11Data={form11Data}
                                                                            getCurrentFormDetail={getCurrentFormDetail}
                                                                            subSections={subSections}
                                                                            currentFormDetail={currentFormDetail}
                                                                            documentCheckList={documentCheckList}
                                                                            sectionId={section.id}
                                                                            getResponseBack={() => getDocumentCheckList({}, 'defaultLoader')}
                                                                        />
                                                                    }

                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                        )}
                                                    </Paper>
                                                }
                                            </div>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </Paper>
                            )}
                        </Fragment>
                    }
                </div>  
                { !skeletonLoader && documentCheckList.length === 0 && onCheckFormPermission(currentFormDetail) ?
                    <ImportTemplate 
                        getResponseBack={() => {getCurrentFormDetail();getDocumentCheckList({},'pageLoader')}}
                    />:null
                }          
                { deleteItem.modal ?
                    <DeletePopUp
                        modal={deleteItem.modal}
                        toggleModalFn={() => setDeleteItem({modal:false, itemType:'', sectionId:null, subSectionId:null})}
                        title={`Delete`}
                        content={<h4>Are you sure you want to Delete?</h4>}
                        deleteRecordFn={() => onDeleteItem()}
                    />:null
                }
                {editItem.modal ?
                    <EditContentItem 
                        editItem={editItem}
                        toggleModalFn={() => setEditItem({modal:false, itemType:'', data:null, sectionId:null, subSectionId:null})}
                        getResponseBack={onEditItemType}
                        documentCheckList={documentCheckList}
                        currentFormDetail={currentFormDetail}
                    />:null
                }
                { importSections.modal?
                    <ImportSections 
                        importSections={importSections}
                        toggleModalFn={() => setImportSections({modal:false})}
                        getResponseBack={() => console.log('hi')}
                    />:null
                }
                { publishFileStatusModel ?
                    <PublishFileStatus  
                        modal={publishFileStatusModel}
                        updateFormStatusFn={(data) => onChangeFormStatusApi(data)} 
                        handleClose={() => setPublishFileStatusModel(false)} 
                    />:null
                }
                
                {isLoading ? <PageLoader/>:null}
            </section>
            { !skeletonLoader?
                    currentFormDetail && Object.keys(currentFormDetail).length && currentFormDetail.current_form.status.id !== 7  ? null : !documentCheckList.length ? <div style={{textAlign:'center', height:`${window.innerHeight-120}px`}}><h4 style={{padding:'50px 0',fontSize:'18px'}}>No Data found</h4></div>:null:null
            }
        </Fragment>
        
    )
}
export default Workbook;